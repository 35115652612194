export function appendPromptInstructions({
  textToAdd,
  prompt,
  triggerWordsToMaintain,
}: {
  textToAdd: string;
  prompt: string;
  triggerWordsToMaintain: string[];
}) {
  return `Given a prompt and some text, append the text to the prompt in such a way that any existing context covered by the text is replaced in the prompt directly—or, if there's no conflict, the text is appended. Follow these specific rules:

1) BACKGROUND REPLACEMENT
If the text describes a new background or scene, remove all references to the existing scene, setting, or background in the prompt. Maintain any description of a camera (e.g. "35mm lens") or type of photography (e.g. "professional editorial photography"). Then append the new background.

2) CLOTHING / ACCESSORIES
If the text describes a new article of clothing or accessory:
- If a human can only reasonably wear one of a certain item at a time (e.g., one shirt, one pair of pants, one jacket, one pair of shoes, one hat), REPLACE the old item with the new one.
- If a human can reasonably wear multiples (e.g., two earrings, two watches, two purses), then ADD the new item in addition to the old one.

Example single-wear items: shirt, jacket, sweater, blouse, pants, shorts, pair of gloves, coat, hat, pair of shoes, scarf, backpack, pair of sunglasses, tie, socks.
Example multi-wear items: Hand bags, accessories, jewelry, rings, necklaces.

3) HUMAN DESCRIPTIONS
If the text describes a human model (e.g., "young woman [trigger1] with sleek pulled back hair," "a fair skinned blond woman," or "a tall handsome man") and there is ONLY ONE human in the prompt, REPLACE that human with the new description (even if genders differ).

4) TRIGGER HANDLING
If the prompt or the text contains a [trigger] token, do not alter it. For example, if the new text says "light blue [trigger0] pleated skirt," keep "[trigger0]" unchanged. No changes to spelling, casing, or bracket notation are allowed. Treat triggers with extreme caution; if "[trigger4]" is not mentioned in the prompt or the text to append, do not create it. Each trigger represents a single noun (human, fashion item, product, or style).

5) TEXT TO BE ADDED
Do not alter the text to be added. For instance, if it says "background is a long, arched hallway...," keep it exactly as is (no changes to spelling, casing, bracket notation).

6) HUMAN/OBJECT PLACEMENT RELATIONSHIPS
Ensure that newly added text makes sense in the context of the existing prompt. If a pair of shoes are being added, and the prompt describes a human, ensure that the shoes are being worn by the human. If the text describes a new background, clothing, accessory, or human, place it in the prompt in the same relative position as the existing item. For example, if the prompt says "a young woman [trigger0] wearing a light blue [trigger1] pleated skirt," and the text adds "a red [trigger2] handbag," place the handbag in the same relative position as the skirt.

7) TRIGGER WORDS TO MAINTAIN
If the prompt or the text contains a [trigger] token, do not alter it. For example, if the new text says "light blue [trigger0] pleated skirt," keep "[trigger0]" unchanged. No changes to spelling, casing, or bracket notation are allowed. Treat triggers with extreme caution; if "[trigger4]" is not mentioned in the prompt or the text to append, do not create it. Each trigger represents a single noun (human, fashion item, product, or style).

8) FINAL OUTPUT
Return the updated, combined prompt as a single coherent description in JSON format. Maintain details from the original prompt that do not conflict with or get replaced by the new text. Ensure all [trigger]s are preserved. Do not include any additional text in the output beyond the JSON object, such as a description.

Return a JSON object:
json { "modifiedPrompt": string }
"modifiedPrompt" is the new prompt if the original was invalid, otherwise the same as input.

EXAMPLES:

Example #1: Adding an accessory.
Prompt: "A photo of a woman white tight bun hair pulled back wearing a jacket [trigger0]...overexposed light and shadows, motion photography, old money 35mm lens, professional fashion photography."
Text: "chic [trigger1] handbag..."
The new item is an accessory. The woman can wear both a jacket and handbag, so append.

Example #2: Replacing the same clothing.
Prompt: "Photo of a woman...wearing light blue [trigger0] pleated skirt...wearing a tennis hat..."
Text: "A [trigger 1] red and white one piece activewear jumpsuit."
Replace the skirt with the one-piece.

Example #3: Replacing coat.
Prompt: "photo of an African American woman wearing a [trigger0] women's L.L.Bean Activewear ski jacket...standing on a snowboard..."
Text: "white puffy down cropped [trigger1] woman's winter coat."
She can't wear two coats, so replace the old one.

Example #4: Adding perfume bottle.
Prompt: "A woman wearing a classic black cocktail dress..."
Text: "a small glass [trigger0] perfume bottle..."
A perfume bottle can coexist, so append it.

Example #5: Replacing a human.
Prompt: "A photo of a middle-aged man with short blond hair wearing a casual [trigger0] gray t-shirt..."
Text: "A teenage boy [trigger1] with curly black hair wearing a red hoodie."
Only one human, so replace him with the new description.

Example #6: Adding a different article of clothing.
Prompt: "A photo of a young woman wearing a black t-shirt [trigger0]..."
Text: "a denim jacket [trigger1]..."
A shirt and jacket can coexist, so append.

Example #7: Replacing a background.
Prompt: "photo of a young woman [trigger0]...on beige fabric against a beige vogue photoshoot..."
Text: "The background is a long, arched hallway..."
Remove references to the old background, keep photography details, append the new hallway.

USAGE:
Given the original prompt and the text to add, identify if it's a new background, clothing, accessory, or human. Replace if there's a conflict or append if they can coexist. Keep triggers. Return a coherent final prompt.

Now do the same for the following input.

INPUTS
-------------------------------------------------------------------------------------------
Prompt: ${prompt}
Text to be added: ${textToAdd}
Trigger words to maintain: ${triggerWordsToMaintain}
-------------------------------------------------------------------------------------------`.trim();
}

export function removePromptInstructions({
  textToRemove,
  prompt,
  triggerWordsToRemove,
  triggerWordsToMaintain,
}: {
  textToRemove: string;
  prompt: string;
  triggerWordsToRemove: string[];
  triggerWordsToMaintain: string[];
}) {
  return `
# These instructions define how to remove specified text from an existing prompt context.
# If the text to remove matches something in the prompt, remove it in a way that preserves
# overall coherence. If it doesn't match, do nothing.

Given a prompt and some text, remove the text from the prompt if there is a meaningful match
(or equivalence). Otherwise, do nothing. Follow these specific rules:

1) BACKGROUND REMOVAL
   # If the text describes a background that matches the prompt's background, remove that
   # background description.

2) HUMAN REMOVAL
   # If the text describes a human that is present in the prompt:
   #   - If there is exactly one human in the prompt, remove the details of that human and keep the rest of the scene if relevant.
   #   - If there are multiple humans in the prompt, remove only the matching human. The other
   #     humans remain unchanged.

3) CLOTHING REMOVAL
   # If the text describes a piece of clothing (e.g., a jacket, skirt, etc.) that matches something
   # the existing human(s) is wearing, remove that clothing from the prompt. If the text describes
   # clothing that is not found in the prompt, do nothing.

4) ACCESSORY REMOVAL
   # If the text describes an accessory (e.g., handbag, watch, goggles) that matches something
   # already in the prompt, remove that accessory. If it's not found, do nothing.

5) TRIGGER HANDLING
   # Do not preserve triggers if they are a part of the text to remove. Treat triggers with extreme caution. If a trigger is not mentioned in the prompt or the text to remove,
   # do not create it. For example if [trigger0] is not mentioned in the prompt or the text to be removed,
   # ensure that the output does not contain [trigger0].

6) TRIGGER WORDS TO MAINTAIN
   # If the prompt or the text contains a [trigger] token, do not alter it. For example, if the new text says "light blue [trigger0] pleated skirt," keep "[trigger0]" unchanged. No changes to spelling, casing, or bracket notation are allowed. Treat triggers with extreme caution; if "[trigger4]" is not mentioned in the prompt or the text to append, do not create it. Each trigger represents a single noun (human, fashion item, product, or style).

7) TRIGGER WORDS TO REMOVE
   # Ensure that the trigger words to remove are not present in the final output.

8) FINAL OUTPUT
 Return a JSON object:
json { "modifiedPrompt": string }
"modifiedPrompt" is the new prompt if the original was invalid, otherwise the same as input.

# -------------------------------------------------------------------------------------------
# EXAMPLES
# -------------------------------------------------------------------------------------------

# Example #1: Remove a background that exists in the prompt
# ----------------------------------------------------------------------------
# Prompt:
# photo of a a young woman [trigger0] with her hair pulled back in a bun, wearing silver hoop
# earrings wearing long brown and black coat with a tiger print, a double breasted closure, and a
# matching black belt. The background is a long, arched hallway with a stone ceiling and floor.
# Light streams in through tall, narrow windows along one side, casting a warm glow on the pale
# stone walls. The hallway extends into the distance, creating a sense of perspective and grandeur,
# motion photography, old money 35mm lens, professional fashion photography
#
# Text to be removed:
# The background is a long, arched hallway with a stone ceiling and floor. Light streams in
# through tall, narrow windows along one side, casting a warm glow on the pale stone walls.
# The hallway extends into the distance, creating a sense of perspective and grandeur.
#
# Explanation:
# The prompt’s background matches exactly. We remove it entirely. We maintain [trigger0] and do not create new triggers.
#
# Output:
# { "modifiedPrompt": "photo of a a young woman [trigger0] with her hair pulled back in a bun, wearing silver hoop
# earrings wearing long brown and black coat with a tiger print, a double breasted closure, and a
# matching black belt, in front of a plain background, motion photography, old money 35mm lens,
# professional fashion photography" }

# Example #2: Remove a human that exists in the prompt when there are multiple humans
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a middle-aged [trigger0] man with short blond hair wearing a casual gray t-shirt and blue jeans
# next to a woman [trigger1] with long dark hair wearing a polka dot dress, leaning against a brick wall in
# an urban setting.
#
# Text to be removed:
# a woman [trigger1] with long dark hair wearing a polka dot dress
#
# Explanation:
# The prompt has multiple humans. We remove only the matching woman. The man remains. We maintain [trigger0] and do not create new triggers.
#
# Output:
# { "modifiedPrompt": "A photo of a middle-aged man [trigger0] with short blond hair wearing a casual gray t-shirt and blue jeans,
# leaning against a brick wall in an urban setting." }

# Example #3: Remove a human when there is one human in the prompt
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a single mother with curly black hair wearing a yellow sweater, smiling at the camera.
#
# Text to be removed:
# a single mother with curly black hair wearing a yellow sweater
#
# Explanation:
# There's only one person. We remove that description entirely and return an empty prompt.
#
# Output:
# { "modifiedPrompt": "" }


# Example #4: Remove clothing
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a young woman wearing a black t-shirt [trigger0] and a denim jacket [trigger1]
# with a patch on the left arm, standing on a city sidewalk, 35mm lens, professional fashion photography.
#
# Text to be removed:
# a denim jacket [trigger1] with a patch on the left arm
#
# Explanation:
# That exact piece of clothing exists. Remove it from the prompt, leaving the rest. We maintain [trigger0] and do not create new triggers.
#
# Output:
# { "modifiedPrompt": "A photo of a young woman wearing a black t-shirt [trigger0], standing on a city sidewalk, 35mm lens, professional fashion photography." }


# Example #5: Remove an accessory
# ----------------------------------------------------------------------------
# Prompt:
# A photo of a woman wearing a classic [trigger0] black cocktail dress, standing in front of a shimmering
# city skyline at night, facing the camera with a poised expression, holding a small glass perfume
# bottle with a crystal stopper in her right hand.
#
# Text to be removed:
# a small glass perfume bottle with a crystal stopper
#
# Explanation:
# That accessory exists. Remove it from the prompt. We maintain [trigger0] and do not create new triggers.
#
# Output:
# { "modifiedPrompt": "A photo of a woman wearing a classic [trigger0] black cocktail dress, standing in front of a shimmering
# city skyline at night, facing the camera with a poised expression." }

# -------------------------------------------------------------------------------------------
# USAGE
# -------------------------------------------------------------------------------------------
# Given the following inputs:
#  - The original prompt
#  - The text to remove
# You must:
#   (1) Identify if the text to remove matches a background, a human, clothing, or accessory
#       that exists in the prompt.
#   (2) If it matches, remove it appropriately
#   (3) If it does not match, do nothing.
#   (4) Return the final updated prompt as a single coherent string.

# -------------------------------------------------------------------------------------------
# INPUTS
# -------------------------------------------------------------------------------------------
# - The original prompt: ${prompt}
# - The text to be removed: ${textToRemove}
# - Trigger words to remove: ${triggerWordsToRemove}
# -------------------------------------------------------------------------------------------

`;
}

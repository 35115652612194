import {
  AppRoleType,
  PublicUserId,
  UploadReferenceHumanArgs,
  UploadReferenceHumanFileArgs,
  UserAssetType,
} from "@/core/common/types";
import { UploadReferenceHumanResponse } from "@/core/common/types/reference-human";
import { debugError } from "@/core/utils/print-utilts";
import { collection, doc, DocumentData, Firestore, where } from "firebase/firestore";
import { Functions, httpsCallable, HttpsCallable } from "firebase/functions";
import {
  createGenerateUserAssetUploadUrlFunction,
  GenerateUserAssetUploadUrlFunction,
  uploadUserAssetFileToStorage,
} from "./asset-upload-utils";

import { PublicTeamId } from "@/core/common/types/team";
import { UserAssetTag } from "@/core/common/types/user-asset-type";
import { FirebaseDocQueryGenerator } from "./firebase-doc-query-generator";

export const referenceHumanCollectionName = "referenceHumans";

export function getReferenceHumanCollectionRef(firestore: Firestore) {
  return collection(firestore, referenceHumanCollectionName);
}

export function getReferenceHumanDocRef({
  firestore,
  humanId,
}: {
  firestore: Firestore;
  humanId: string;
}) {
  return doc(getReferenceHumanCollectionRef(firestore), humanId);
}

export class ReferenceHumanManager {
  private firestore: Firestore;
  private firebaseFunctions: Functions;

  private uploadReferenceHumanCallable: HttpsCallable<
    UploadReferenceHumanArgs,
    UploadReferenceHumanResponse
  >;

  private generateUserAssetUploadUrlColabJuly24: GenerateUserAssetUploadUrlFunction;

  constructor({
    firestore,
    firebaseFunctions,
  }: {
    firestore: Firestore;
    firebaseFunctions: Functions;
  }) {
    this.firestore = firestore;
    this.firebaseFunctions = firebaseFunctions;

    this.uploadReferenceHumanCallable = httpsCallable(
      firebaseFunctions,
      "uploadReferenceHumanColabJuly24_v2",
    );

    this.generateUserAssetUploadUrlColabJuly24 = createGenerateUserAssetUploadUrlFunction({
      firebaseFunctions,
    });
  }

  private getReferenceHumanCollectionRef() {
    return getReferenceHumanCollectionRef(this.firestore);
  }

  private getReferenceHumanDocRef(humanId: string) {
    return getReferenceHumanDocRef({
      firestore: this.firestore,
      humanId,
    });
  }

  async uploadReferenceHumanFile(
    args: UploadReferenceHumanFileArgs,
  ): Promise<UploadReferenceHumanResponse> {
    try {
      const faceImageStoragePath = await uploadUserAssetFileToStorage({
        data: args.faceImage,
        publicTeamId: args.publicTeamId,
        assetType: UserAssetType.ReferenceImage,
        tags: [UserAssetTag.ReferenceFaceImage],
        generateAssetUploadUrl: this.generateUserAssetUploadUrlColabJuly24,
      });

      if (!faceImageStoragePath) {
        return {
          ok: false,
          message: "Unable to upload reference face image.",
        };
      }

      const response = await this.uploadReferenceHumanCallable({
        faceImageStoragePath,
      });

      return response.data;
    } catch (error) {
      debugError("Error uploading reference human to storage ", error);

      return {
        ok: false,
        message: "Internal server error.",
      };
    }
  }

  getReferenceHumanGenerator({
    publicUserId,
    publicTeamId,
    batchSize = 10,
  }: {
    batchSize: number;
    publicUserId: PublicUserId;
    publicTeamId: PublicTeamId;
  }) {
    return new FirebaseDocQueryGenerator<DocumentData>({
      baseQuery: this.getReferenceHumanCollectionRef(),
      batchSize,
      queryConstraints: [
        where("publicTeamId", "==", publicTeamId),
        where(`roles.${publicUserId}`, "in", Object.values(AppRoleType)),
      ],
    });
  }
}

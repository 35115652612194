import { ElementsSearchManager } from "@/backend/firebase/elements-manager";
import {
  RenderJobController,
  RenderJobControllerV2,
  RenderProcessController,
} from "@/core/common/interfaces";
import { IScene, SampleProjectScene } from "@/core/common/scene";
import type {
  AppRoleType,
  AppUser,
  AppUserQuotas,
  AppUserSubscriptionTier,
  BackendCallableResponseData,
  CollaborationV1AssetStoragePath,
  CustomModelFixDetailsFalArgsOverride,
  CustomModelPredictionItem,
  CustomModelScaleConfigs,
  CustomModelTrainingItem,
  CustomModelType,
  DocsBatchGenerator,
  EditorAssetContentType,
  EraseProductArgs,
  GenerateTemplateItem,
  HandleCreateCustomModelArgs,
  HandleCreateCustomModelResponse,
  HandleCustomModelTrainingStartArgs,
  HandleCustomModelTrainingStartResponse,
  HandleCustomModelTrainingStopArgs,
  HandleCustomModelTrainingStopResponse,
  HandleStartCustomModelPredictionArgs,
  HandleStartCustomModelPredictionResponse,
  HandleStopCustomModelPredictionArgs,
  HandleStopCustomModelPredictionResponse,
  HttpsCallable,
  HttpsCallableResult,
  ObjectBounds2d,
  PublicUserId,
  RegenerateProductArgs,
  TryOnModelPreviewData,
  UploadReferenceHumanFileArgs,
  UserAssetType,
  UserOnboardData,
  UserProject,
  UserProjectType,
} from "@/core/common/types";
import { CustomModelDatasetItem, PastGeneration } from "@/core/common/types";
import { FalAnyLLMArgs, FalAnyLLMResponse, ModelEnum } from "@/core/common/types/any-llm";
import {
  ApiModelType,
  ApiUsageDoc,
  EmailApiDataDoc,
  UserApiDataDoc,
} from "@/core/common/types/api";
import { AssetMetadata } from "@/core/common/types/assetV2";
import {
  ColorCorrectV2Args,
  ColorCorrectV2RenderJobDoc,
  ColorCorrectV2Response,
} from "@/core/common/types/color-correct-v2";
import { UserCustomClaims } from "@/core/common/types/custom-claims";
import { CustomModelDataset, CustomModelInfo } from "@/core/common/types/custom-model-types";
import { PricingConfig, PricingConfigVersion } from "@/core/common/types/pricing-config";
import { PublicUserMetadata } from "@/core/common/types/public-user-id";
import { UploadReferenceHumanResponse } from "@/core/common/types/reference-human";
import { RenderPipelineArgs, RenderPipelineType } from "@/core/common/types/render-args";
import {
  StripeCheckoutSessionData,
  StripeListInvoicesParams,
  StripeListInvoicesResponse,
  StripePrice,
  StripeProduct,
  StripeSubscriptionFirestoreDoc,
} from "@/core/common/types/stripe";
import {
  CreateStripePortalLinkParams,
  CreateStripePortalLinkResponse,
} from "@/core/common/types/stripe-portal";
import {
  StripeSubscriptionPriceId,
  StripeSubscriptionProductId,
} from "@/core/common/types/subscription";
import { PublicTeamId, TeamMetadata, TeamMetadataCollection } from "@/core/common/types/team";
import {
  FlairTemplateGenerator,
  GenerateTemplateTypesenseDoc,
} from "@/core/common/types/template-generator";
import { UpscaleModelType } from "@/core/common/types/upscale";
import { UserAssetTag } from "@/core/common/types/user-asset-type";
import {
  GenerateVideoKeyFrames,
  VideoCameraMotion,
  VideoGenerationDoc,
  VideoGenerationRequest,
  VideoGenerationResponse,
} from "@/core/common/types/video";
import { BackendUserFeatureFlags } from "@/core/controllers/featureFlags/featureFlags";
import { Timestamp } from "firebase/firestore";
import {
  ClarityUpscaleInput,
  ClarityUpscaleResponse,
  FaceUpscalerInput,
  FaceUpscalerResponse,
  FixProductDetailsInput,
  FixProductDetailsResponse,
  GetMaskFromPromptInput,
  GetMaskFromPromptResponse,
  InContextVariationsInput,
  InContextVariationsResponse,
  MultiStageGenerationInput,
  MultiStageGenerationResponse,
  SwapFaceInput,
  SwapFaceResponse,
  TryOnInput,
  TryOnResponse,
} from "./custom-model-post-process";
import { GenerateUserAssetUploadUrlArgs } from "./firebase/asset-upload-utils";
import { BuildAHumanArgs, BuildAHumanResponse } from "./firebase/build-a-human-manager";
import type {
  InviteUsersToTeamArgs,
  InviteUsersToTeamResponse,
  OnPublicTeamQuotasUpdateArgs,
  OnUserTeamsUpdateArgs,
  RemoveUserFromTeamArgs,
  RemoveUserFromTeamResponse,
  UpdateUserTeamNameArgs,
  UpdateUserTeamNameResponse,
  UpdateUserTeamRoleArgs,
  UpdateUserTeamRoleResponse,
} from "./firebase/teams-manager";
import { type TryOnPreviewGenerator } from "./firebase/tryon-preview-generator";
type ImageType = string;

export type CreateSubscriptionsPortalLinkArgs = {
  returnUrl?: string;
};

export type CreateSubscriptionsPortalLinkCallable = HttpsCallable<
  CreateSubscriptionsPortalLinkArgs,
  BackendCallableResponseData
>;

export type ParseClothImageResult = {
  image_parsed: string;
  image_mask?: string;
  warped_cloth?: string;
  warped_human_mask?: string;
  bbox?: [number, number, number, number];
};

export function isParseClothImageResult(object: any): object is ParseClothImageResult {
  return object && typeof object.image_parsed === "string";
}

export type WarpParsedImageResult = {
  warped_cloth?: string;
  warped_human_mask?: string;
};

export function isWarpParsedImageResult(object: any): object is WarpParsedImageResult {
  return (
    object &&
    typeof object.warped_cloth === "string" &&
    typeof object.warped_human_mask === "string"
  );
}

export type StartRenderJobFunctionArgs = {
  renderPipelineType: RenderPipelineType;
  renderPipelineArgs: RenderPipelineArgs;
};

export type RenderClothImageArgs = {
  render_overview_prompt: string;
  render_overview_negative_prompt?: string;
  face_prompt: string;
  face_negative_prompt?: string;
  warped_cloth_image: ImageType;
  warped_cloth_human_mask_image?: ImageType;
  warped_cloth_mask_image?: ImageType;
  model_image_id?: string;
  model_image?: ImageType;
  model_cloth_mask_image?: ImageType;
  model_parse_image?: ImageType;
  model_preserve_mask_image?: ImageType;
  target_width?: number;
  target_height?: number;
  num_inference_steps?: number;
  crop_expand_ratio?: number;
};

export type ImageCaptionArgs = {
  imageUrl: string;
  prompt?: string;
  extractSubject?: boolean;
};

export type RenderClothImageResult = {
  image: string;
};

export function isRenderClothImageResult(object: any): object is RenderClothImageResult {
  return object?.image && typeof object.image === "string";
}

export type ReceiveRenderResultHandler = (params: {
  imageUrl: string;
  index: number;
}) => Promise<void>;

export type StartRenderJobArgs = StartRenderJobFunctionArgs & {
  userSubscriptionTier?: AppUserSubscriptionTier;
  onRenderProgress?: (progress?: number) => void;
  onReceiveRenderResult?: ReceiveRenderResultHandler;
  renderJobController?: RenderJobControllerV2;
};

export type StopRenderJobArgs = {
  userId: string;
  jobId: string;
};

export type StartRegenerateProductJobArgs = RegenerateProductArgs & {
  userSubscriptionTier?: AppUserSubscriptionTier;
  renderProcessController?: RenderProcessController;
  onReceiveRenderResult?: ReceiveRenderResultHandler;
};

export type StartRegenerateProductJobResponse = string[];

export type StartEraseProductJobArgs = EraseProductArgs & {
  userSubscriptionTier?: AppUserSubscriptionTier;
  renderProcessController?: RenderProcessController;
  onReceiveRenderResult?: ReceiveRenderResultHandler;
};

export type StartEraseProductJobResponse = {
  erased_image: string;
  mask_image: string;
};

export type CheckoutSessionDiscount = {
  coupon: string;
};

export type CreateCheckoutSessionParams = Partial<StripeCheckoutSessionData> & {
  success_url?: string;
  cancel_url?: string;
  allow_promotion_codes?: boolean;
  discounts?: CheckoutSessionDiscount[];
};

export type CreateCheckoutSessionResult = {
  url?: string;
  message?: string;
};

export type GetApiUsageGeneratorArgs = {
  batchSize: number;
  modelType?: ApiModelType;
};

export type OnApiUsageUpdateArgs = {
  onUpdate: (generations: any[]) => void;
  batchSize: number;
  modelType?: ApiModelType;
};

export type UpscaleImageArgs = {
  modelType: UpscaleModelType;
  userSubscriptionTier?: AppUserSubscriptionTier;
  renderProcessController?: RenderJobController;
  imageUrl: string;
  inputImageUrl?: string;
  upscale?: 2 | 4;
  onError?: ((error: Error) => void) | undefined;
  prompt?: string;
  max_filter_size?: number;
  img2img_strength?: number;
  num_inference_steps?: number;
  guided_filter_radius?: number;
  guided_filter_eps?: number;
  guided_filter_strength?: number;
};

export type UpdateStripeSusbcriptionArgs = {
  fromProductId: StripeSubscriptionProductId;
  toProductId: StripeSubscriptionProductId;
  toPriceId?: StripeSubscriptionPriceId;
};

export type UpdateStripeSusbcriptionResponse = {
  updated: boolean;
  message: string;
};

export type OnColorCorrectV2UpdateArgs = {
  uid: string;
  jobId: string;
  onUpdate: (doc?: ColorCorrectV2RenderJobDoc) => void;
};

export interface CancelColorCorrectV2JobArgs {
  uid: string;
  jobId: string;
}

export interface OneTimePaymentMetadata {
  numCustomModelTrainings?: number;
  numCustomModelPredictions?: number;
  numVideoGenerations?: number;
}

export interface CreateOneTimePaymentCheckoutSessionArgs {
  priceId: StripeSubscriptionPriceId;
  quantity: number;
  metadata: OneTimePaymentMetadata;
  successUrl?: string;
  cancelUrl?: string;
  allowPromotionCodes?: boolean;
}

export type CreateOneTimePaymentCheckoutSessionResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      sessionId: string;
      url: string;
    };

export interface CreateCustomOneTimePaymentCheckoutSessionArgs {
  priceId: string;
  quantity?: number;
  successUrl?: string;
  cancelUrl?: string;
  dryRun?: boolean;
  allowPromotionCodes?: boolean;
}

export type CreateCustomOneTimePaymentCheckoutSessionResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      sessionId: string;
      url: string;
    };

export interface OnUserCustomModelsUpdateArgs {
  publicUserId: PublicUserId;
  publicTeamId?: PublicTeamId;
  callback: (customModels: Record<string, CustomModelInfo>) => void;
}

export interface AppEnvironmentVairables {
  VITE_SEGMENT_API_KEY: string;
  VITE_MIXPANEL_API_KEY: string;
  // VITE_RENDER_API_URL: string;
  // VITE_RENDER_CANNY_API_URL: string;
  VITE_REPLACE_PRODUCT_API_URL: string;
  VITE_ERASE_PRODUCT_APP_URL: string;
  VITE_SAM_API_KEY: string;
  VITE_TRYON_PARSE_CLOTH_API_URL: string;
  VITE_TRYON_RENDER_CLOTH_API_URL: string;
  VITE_TRYON_WARP_CLOTH_API_URL: string;
  VITE_CAPTION_API_URL: string;
  VITE_TRYON_SECRET_KEY: string;
  VITE_CLIP_API_URL: string;
  VITE_CLIP_API_KEY: string;
  VITE_IMAGE_API_URL: string;
  VITE_IMAGE_API_KEY: string;
  VITE_CAPTION_SECRET_KEY: string;
  VITE_SEARCH_TEMPLATES_API_URL: string;
  VITE_RENDER_API_ENDPOINT_URL: string;
  VITE_UPSCALE_API_URL: string;
  VITE_UPSCALE_STATE_MANAGER_URL: string;
  VITE_API_PRICING_FACTOR: string;
  VITE_API_QUANTITY_PER_STEP: string;
  VITE_API_QUANTITY_PER_LENGTH: string;
  VITE_API_QUANTITY_PER_CONTROL_IMAGE: string;
  VITE_API_MIN_QUANTITY: string;
  VITE_API_PRICE_PER_QUANTITY: string;
  VITE_CONVERT_IMAGE_API_URL: string;
  VITE_REALTIME_SERVER_API_URL: string;
  VITE_REALTIME_SERVER_TIMEOUT_SECONDS: number;
  VITE_REALTIME_COLOR_CORRECTION_API_URL: string;
  VITE_REMOVE_BACKGROUND_API_URL: string;
  VITE_VERSION: string;
}

export type RefreshCustomUserClaimsResponse = {
  ok: boolean;
  customClaims: UserCustomClaims;
};

export type CreateCustomModelArgs = Omit<HandleCreateCustomModelArgs, "type">;
export type StartCustomModelTrainingArgs = Omit<HandleCustomModelTrainingStartArgs, "type">;
export type StopCustomModelTrainingArgs = Omit<HandleCustomModelTrainingStopArgs, "type">;
export type StartCustomModelPredictionArgs = Omit<HandleStartCustomModelPredictionArgs, "type">;
export type StopCustomModelPredictionArgs = Omit<HandleStopCustomModelPredictionArgs, "type">;

export type DeleteCustomModelArgs = {
  modelId: string;
};

export type DeleteCustomModelResponse = {
  ok: boolean;
  message: string;
};

export type DeleteCustomModelPredictionArgs = {
  predictionId: string;
};

export type DeleteCustomModelPredictionResponse = {
  ok: boolean;
  message: string;
};

export type OnCustomModelPredictionUpdateArgs = {
  predictionId: string;
  callback: (prediction: CustomModelPredictionItem) => void;
};

export type UpdateCustomModelInfoArgs = {
  modelId: string;
  modelInfo: Partial<CustomModelInfo>;
};

export type GetCustomModelTrainingArgs = {
  modelId: string;
  trainingId: string;
};

export type GetCustomModelTrainingsArgs = {
  modelId: string;
};

export type GetCustomModelTrainingsResponse = CustomModelTrainingItem[];

export type OnCustomModelTrainingUpdateArgs = {
  modelId: string;
  trainingId: string;
  callback: (trainingItem: CustomModelTrainingItem) => void;
};

export type OnCustomModelTrainingCollectionUpdateArgs = {
  modelId: string;
  callback: (trainingItems: CustomModelTrainingItem[]) => void;
};

export type UploadCustomModelDataItemToStorageArgs = {
  data: File | Blob;
  modelId: string;
  publicTeamId: PublicTeamId;
};

export type UploadCustomModelDataItemToStorageResponse = string | undefined;

export type UploadCustomModelVirtualTryOnInputToStorageArgs = {
  data: File | Blob;
  publicTeamId: PublicTeamId;
};

export type UploadCustomModelVirtualTryOnInputToStorageResponse = string | undefined;

export type UploadCustomModelAssetToStorageArgs = Omit<
  GenerateUserAssetUploadUrlArgs,
  "contentType"
> & {
  data: File | Blob;
};

export type UploadCustomModelAssetToStorageResponse = string | undefined;

export type GetPublicCustomModelPredictionsArgs = {
  modelId?: string;
};

export type GetPublicCustomModelPredictionsResponse = Record<string, CustomModelPredictionItem>;

export type OnCustomModelPredictionsUpdateArgs = {
  publicUserId: PublicUserId;
  modelId?: string;
  publicTeamId: PublicTeamId;
  callback: (predictions: Record<string, CustomModelPredictionItem>) => void;
};

export interface UpdateCustomModelDatasetItemThumbnailArgs {
  teamId: PublicTeamId;
  modelId: string;
  assetStoragePath: CollaborationV1AssetStoragePath;
}

export type UpdateCustomModelDatasetItemThumbnailResponse = {
  ok: boolean;
  message: string;
};

export interface AutoCorrectCustomModelCaptionArgs {
  inputCaption: string;
  subjectCaption: string;
  customModelType: CustomModelType;
}

interface AutoCorrectCaptionResponseData {
  modifiedCaption: string;
  message: string;
  isValid: boolean;
}

export interface AutoCorrectCustomModelCaptionResponse {
  ok: boolean;
  data?: AutoCorrectCaptionResponseData;
}

export interface AppendTextToPromptArgs {
  textToAdd: string;
  prompt: string;
  triggerWordsToMaintain: string[];
  languageModel?: ModelEnum;
}

export interface AppendTextToPromptResponse {
  ok: boolean;
  data: {
    modifiedPrompt: string;
  };
}

export interface RemoveTextFromPromptArgs {
  textToRemove: string;
  prompt: string;
  triggerWordsToRemove: string[];
  triggerWordsToMaintain: string[];
  languageModel?: ModelEnum;
}

export interface RemoveTextFromPromptResponse {
  ok: boolean;
  data: {
    modifiedPrompt: string;
  };
}

export interface OutpaintImageArgs {
  imageDataURL: string;
  prompt?: string;
  numberOfImages?: number;
}

export type OutpaintImageResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      imageDataUrls: string[];
    };

export type OnVideoGenerationDocUpdateArgs = {
  generationId: string;
  callback: (generation: VideoGenerationDoc | undefined) => void;
};

export type OnUserVideoGenerationsUpdateArgs = {
  publicUserId: PublicUserId;
  publicTeamId?: PublicTeamId;
  callback: (generations: Record<string, VideoGenerationDoc>) => void;
};

export type UploadVideoKeyFrameToStorageArgs = {
  data: File | Blob;
};

export type UploadVideoKeyFrameToStorageResponse = string | undefined;

export type GenerateVideoPromptArgs = {
  keyFrames: GenerateVideoKeyFrames;
  cameraMotion?: VideoCameraMotion;
};

export type GenerateVideoPromptResponse = {
  prompt: string;
  cameraMotion: VideoCameraMotion | undefined;
} | null;

export type OnPricingConfigUpdateArgs = {
  version: PricingConfigVersion;
  callback: (pricingConfig: PricingConfig | undefined) => void;
};
export interface ModelTrainingPair {
  model_id: string;
  training_id: string;
}

export interface CustomModelFixDetailsArgs {
  inputImageUrl: string;
  customModelScaleConfigs: CustomModelScaleConfigs;
  shortPrompt: string;
  fullPrompt: string;
  inputMaskImageUrl?: string;
  extraPredictionArgs?: CustomModelFixDetailsFalArgsOverride;
}

export type CustomModelFixDetailsResponse =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      message: string;
      prediction_id?: string;
    };

export function isCustomModelFixDetailsResponse(obj: any): obj is CustomModelFixDetailsResponse {
  if (!obj) {
    return false;
  }

  if (obj.ok === true) {
    return (
      typeof obj.message === "string" &&
      (typeof obj.prediction_id === "undefined" || typeof obj.prediction_id === "string")
    );
  }

  if (obj.ok === false) {
    return typeof obj.message === "string";
  }

  return false;
}

export function isCustomModelFixDetailsArgs(arg: any): arg is CustomModelFixDetailsArgs {
  return (
    typeof arg === "object" &&
    typeof arg.image_data_url === "string" &&
    Array.isArray(arg.model_training_pairs) &&
    arg.model_training_pairs.every(isModelTrainingPair) &&
    typeof arg.short_caption === "string" &&
    typeof arg.full_inpainting_prompt === "string" &&
    typeof arg.uid === "string" &&
    (arg.mask_image_data_url_override === undefined ||
      typeof arg.mask_image_data_url_override === "string") &&
    (arg.fal_args_override === undefined || isFalArgsOverride(arg.fal_args_override))
  );
}

export interface FixCustomModelDetailsCorrespondenceInput {
  sourceImage?: string;
  sourceMask?: string;
  targetImage?: string;
  targetMask?: string;
  certaintyThreshold?: number;
  dilateKernelSize?: number;
  blurKernelSize?: number;
  erodeKernelSize?: number;
  postprocessMaskMinFilterSize?: number;
  sourceCustomModelPredictionId?: string;
}

export type FixCustomModelDetailsCorrespondenceOutput =
  | {
      ok: false;
      message: string;
    }
  | {
      ok: true;
      predictionId?: string | null;
      outputStoragePath?: string;
    };

/**
 * Type guard to check if a value is FixCustomModelDetailsCorrespondenceOutput
 * @param obj - The object to check
 * @returns - True if obj is FixCustomModelDetailsCorrespondenceOutput, else false
 */
export function isFixCustomModelDetailsCorrespondenceOutput(
  obj: any,
): obj is FixCustomModelDetailsCorrespondenceOutput {
  // Ensure obj is an object and not null
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  // Check that 'ok' property exists and is a boolean
  if (typeof obj.ok !== "boolean") {
    return false;
  }

  if (obj.ok === false) {
    // When ok is false, 'message' must be a string and no other properties should interfere
    return typeof obj.message === "string";
  } else {
    // When ok is true:
    // - 'predictionId' if present, must be a string
    // - 'outputStoragePath' if present, must be a string
    // - 'message' should not be present
    if ("message" in obj) {
      return false;
    }

    if ("outputStoragePath" in obj && typeof obj.outputStoragePath !== "string") {
      return false;
    }

    return true;
  }
}

export interface ShortenCaptionArgs {
  caption: string;
}

export type ShortenCaptionResponse = string | undefined;

export function isModelTrainingPair(arg: any): arg is ModelTrainingPair {
  return (
    typeof arg === "object" &&
    typeof arg.model_id === "string" &&
    typeof arg.training_id === "string"
  );
}

export function isFalArgsOverride(arg: any): arg is CustomModelFixDetailsFalArgsOverride {
  return (
    typeof arg === "object" &&
    (arg.num_inference_steps === undefined || typeof arg.num_inference_steps === "number") &&
    (arg.guidance_scale === undefined || typeof arg.guidance_scale === "number") &&
    (arg.num_images === undefined || typeof arg.num_images === "number") &&
    (arg.enable_safety_checker === undefined || typeof arg.enable_safety_checker === "boolean") &&
    (arg.output_format === undefined || typeof arg.output_format === "string") &&
    (arg.strength === undefined || typeof arg.strength === "number") &&
    (arg.seed === undefined || typeof arg.seed === "number")
  );
}

export interface SetSubscriptionProductIdQuotaParams {
  productId: string;
  quotas: Partial<AppUserQuotas>;
}

export interface SetSubscriptionProductIdQuotaResponse {
  success: boolean;
  message?: string;
}

export function isSetSubscriptionProductIdQuotaResponse(
  obj: any,
): obj is SetSubscriptionProductIdQuotaResponse {
  return typeof obj === "object" && typeof obj.success === "boolean";
}

export interface UpdateAssetMetadataArgs {
  assetId: string;
  assetMetadata: Partial<AssetMetadata>;
}

export interface GetAssetMetadataGeneratorArgs {
  publicUserId: PublicUserId;
  publicTeamId?: PublicTeamId;
  assetType?: UserAssetType;
  tag?: UserAssetTag;
  batchSize?: number;
}
export interface GetThumbnailPathFromAssetStoragePathArgs {
  storagePath: string;
}
export type GetAssetMetadataGeneratorResponse = DocsBatchGenerator<unknown>;

export interface GetReferenceHumanGeneratorArgs {
  batchSize: number;
  publicUserId: PublicUserId;
  publicTeamId: PublicTeamId;
}

export type GetReferenceHumanGeneratorResponse = DocsBatchGenerator<unknown>;

export interface SetUserQuotas {}
export interface ExternalStorageManager {
  getDownloadUrlFromStoragePath: (path: string) => Promise<string>;

  uploadDataUrlToStorage: (params: {
    data: string;
    contentType: EditorAssetContentType;
    assetId?: string;
  }) => Promise<string>;
  uploadJsonToStorage: (params: { data: string; assetId?: string }) => Promise<string>;
  isStoragePathURL: (value: string) => boolean;
  cleanupStoragePathURL(storagePath: string): string;
  uploadFileToStorage: (params: {
    data: File | Blob;
    contentType: EditorAssetContentType;
  }) => Promise<string>;
  uploadFileToDatasetStorage: (params: {
    data: File | Blob;
    modelId: string;
    contentType: EditorAssetContentType;
  }) => Promise<string>;
  deleteAssetMetadata: (params: { assetId: string }) => Promise<string>;
}
export interface Backend {
  /**
   * New assetMetadataV1 logic starts here
   */
  getAssetMetadata: (assetId: string) => Promise<AssetMetadata | undefined>;
  updateAssetMetadata: (args: UpdateAssetMetadataArgs) => Promise<void>;
  getAssetMetadataFromStoragePath: (storagePath: string) => Promise<AssetMetadata | undefined>;
  getAssetMetadataGenerator: (
    args: GetAssetMetadataGeneratorArgs,
  ) => GetAssetMetadataGeneratorResponse;
  startRenderJob: (args: StartRenderJobArgs) => Promise<unknown>;
  stopRenderJob: (args: StopRenderJobArgs) => Promise<void>;
  startRegenerateProductJob: (
    args: StartRegenerateProductJobArgs,
  ) => Promise<StartRegenerateProductJobResponse>;
  startEraseProductJob: (args: StartEraseProductJobArgs) => Promise<StartEraseProductJobResponse>;
  onRenderJobUpdate: (
    reference: string,
    onNext: (renderJob: any) => void,
    onError?: ((error: Error) => void) | undefined,
    onCompletion?: (() => void) | undefined,
  ) => () => void;
  removeBackground: (params: {
    imageUrl: string;
    onError?: ((error: Error) => void) | undefined;
  }) => Promise<string | null>;
  upscaleImage: (params: UpscaleImageArgs) => Promise<string | null>;
  onAuthStateChanged(
    observer: (user: AppUser | null) => void,
    onError?: ((error: any) => void) | undefined,
  ): () => void;
  onUserLogin: (params: { uid?: string }) => Promise<void>;
  continueWithGoogle: ({ isMobile }: { isMobile?: boolean }) => Promise<AppUser | null>;
  refreshCustomUserClaims: () => Promise<UserCustomClaims | null>;
  sendSignInLinkToEmail: (email: string) => Promise<unknown>;
  signInWithEmailLink: (email: string | null) => Promise<unknown>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<unknown>;
  signInWithCustomToken: (token: string) => Promise<AppUser | null>;
  getPublicUserId: () => Promise<PublicUserId | undefined>;
  getProjectUsers: (project: string) => Promise<Record<string, AppRoleType> | undefined>;
  deleteProject: (projectId: string) => Promise<void>;
  onUserProjectsUpdate: (
    userId: string,
    callback: (snapshot: Record<string, UserProject>) => void,
  ) => () => void;
  getProjectDisplayName: (projectId: string) => Promise<string | undefined>;
  setProjectDisplayName: (projectId: string, displayName: string) => Promise<void>;
  setProjectType: (projectId: string, projectType: UserProjectType) => Promise<void>;
  getProjectType: (projectId: string) => Promise<UserProjectType | undefined>;
  setProjectThumbnail: (projectId: string, thumbnail: string) => Promise<void>;
  createNewProject: HttpsCallable<
    { publicTeamId: string; displayName?: string | null },
    UserProject | null
  >;
  onUserQuotasUpdate: (params: {
    userId?: string;
    onUpdate: (quotas: AppUserQuotas) => void;
  }) => () => void;
  getUserSubscriptions: () => Promise<any | undefined>;
  onUserSubscriptionUpdate: (
    callback: (data?: StripeSubscriptionFirestoreDoc[]) => void,
  ) => () => void;
  getPricingConfig: (version: PricingConfigVersion) => Promise<PricingConfig | undefined>;
  onPricingConfigUpdate: (args: OnPricingConfigUpdateArgs) => () => void;
  getProjectSceneData: (projectId: string) => Promise<IScene | undefined>;
  setProjectSceneData: (
    projectId: string,
    scene: IScene,
  ) =>
    | Promise<{
        isUpdated: boolean;
        scene?: IScene;
      }>
    | undefined;
  signOutUser: () => Promise<void>;
  getUserOnboardData: (uid?: string) => Promise<UserOnboardData>;
  setUserOnboardData(params: {
    uid?: string;
    userOnboardData?: Partial<UserOnboardData>;
  }): Promise<void>;
  isInviteCodeValid(inviteCode: string): Promise<{
    exists: boolean;
    isUsed: boolean;
  }>;
  setInviteCodeUsed(
    inviteCode: string,
    email: string,
    version?: "v1" | "v2",
  ): Promise<HttpsCallableResult<BackendCallableResponseData>>;
  doesUserHaveInviteCode(): Promise<
    HttpsCallableResult<BackendCallableResponseData & { inviteCode?: string }>
  >;
  getSampleProjectScene(params: { storagePath: string }): Promise<SampleProjectScene | null>;
  addPastGeneration(params: { pastGeneration: PastGeneration }): Promise<{
    isUpdated: boolean;
    message: string;
  }>;
  getPastGeneration: (params: { generationId: string }) => Promise<any>;
  initializePastGenerator: (params: { batchSize: number; earliestTimeModified: Timestamp }) => void;
  getPastGenerationGenerator: () => DocsBatchGenerator<any> | undefined;
  createSubscriptionsPortalLink: (
    args: CreateSubscriptionsPortalLinkArgs,
  ) => Promise<string | undefined>;
  createCheckoutSession: (
    params: CreateCheckoutSessionParams,
  ) => Promise<CreateCheckoutSessionResult>;
  createOneTimePaymentCheckoutSession: (
    args: CreateOneTimePaymentCheckoutSessionArgs,
  ) => Promise<CreateOneTimePaymentCheckoutSessionResponse>;
  createCustomOneTimePaymentCheckoutSession: (
    args: CreateCustomOneTimePaymentCheckoutSessionArgs,
  ) => Promise<CreateCustomOneTimePaymentCheckoutSessionResponse>;
  deleteCustomModel: (args: DeleteCustomModelArgs) => Promise<DeleteCustomModelResponse>;
  getCustomModelDataset: (modelId: string) => Promise<CustomModelDataset>;
  onCustomModelDatasetUpdate: (
    modelId: string,
    callback: (dataset?: CustomModelDataset) => void,
  ) => () => void;
  deleteCustomModelDataItem: (params: { modelId: string; dataId: string }) => Promise<void>;
  onCustomModelDatasetItemUpdate: (
    modelId: string,
    dataId: string,
    callback: (dataItem?: CustomModelDatasetItem) => void,
  ) => () => void;
  onUserCustomModelsUpdate: (args: OnUserCustomModelsUpdateArgs) => () => void;
  getPublicCustomModels: () => Promise<CustomModelInfo[]>;
  setCustomModelDataItem: (params: {
    modelId: string;
    dataId: string;
    data: Partial<CustomModelDatasetItem>;
  }) => Promise<void>;
  updateCustomModelDataItem: (params: {
    modelId: string;
    dataId: string;
    data: Partial<CustomModelDatasetItem>;
  }) => Promise<void>;
  uploadCustomModelDataItemToStorage: (
    args: UploadCustomModelDataItemToStorageArgs,
  ) => Promise<UploadCustomModelDataItemToStorageResponse>;
  createCustomModel: (args: CreateCustomModelArgs) => Promise<HandleCreateCustomModelResponse>;
  startCustomModelTraining: (
    args: StartCustomModelTrainingArgs,
  ) => Promise<HandleCustomModelTrainingStartResponse>;
  getCustomModelTraining: (
    args: GetCustomModelTrainingArgs,
  ) => Promise<CustomModelTrainingItem | undefined>;
  getCustomModelTrainings: (
    args: GetCustomModelTrainingsArgs,
  ) => Promise<GetCustomModelTrainingsResponse>;
  onCustomModelTrainingUpdate: (args: OnCustomModelTrainingUpdateArgs) => () => void;
  onCustomModelTrainingCollectionUpdate: (
    args: OnCustomModelTrainingCollectionUpdateArgs,
  ) => () => void;
  stopCustomModelTraining: (
    args: StopCustomModelTrainingArgs,
  ) => Promise<HandleCustomModelTrainingStopResponse>;
  startCustomModelPrediction: (
    args: StartCustomModelPredictionArgs,
  ) => Promise<HandleStartCustomModelPredictionResponse>;
  stopCustomModelPrediction: (
    args: StopCustomModelPredictionArgs,
  ) => Promise<HandleStopCustomModelPredictionResponse>;
  getCustomModelPrediction: (
    predictionId: string,
  ) => Promise<CustomModelPredictionItem | undefined>;
  deleteCustomModelPrediction: (
    args: DeleteCustomModelPredictionArgs,
  ) => Promise<DeleteCustomModelPredictionResponse>;
  getPublicCustomModelPredictions: (
    args: GetPublicCustomModelPredictionsArgs,
  ) => Promise<GetPublicCustomModelPredictionsResponse>;
  onCustomModelPredictionUpdate: (args: OnCustomModelPredictionUpdateArgs) => () => void;
  onCustomModelPredictionsUpdate: (args: OnCustomModelPredictionsUpdateArgs) => () => void;
  getCustomModelInfo: (modelId: string) => Promise<CustomModelInfo | undefined>;
  updateCustomModelInfo: (args: UpdateCustomModelInfoArgs) => Promise<void>;
  customModelFixProductDetails: (
    args: FixProductDetailsInput,
  ) => Promise<FixProductDetailsResponse>;
  customModelGetMaskFromPrompt: (
    args: GetMaskFromPromptInput,
  ) => Promise<GetMaskFromPromptResponse>;
  customModelSwapFace: (args: SwapFaceInput) => Promise<SwapFaceResponse>;
  customModelGenerateVariations: (
    args: InContextVariationsInput,
  ) => Promise<InContextVariationsResponse>;
  customModelFixDetailsCorrespondence: (
    input: FixCustomModelDetailsCorrespondenceInput,
  ) => Promise<FixCustomModelDetailsCorrespondenceOutput>;
  customModelUpscaleCreative: (args: ClarityUpscaleInput) => Promise<ClarityUpscaleResponse>;
  customModelUpscaleFace: (args: FaceUpscalerInput) => Promise<FaceUpscalerResponse>;
  customModelMultistepGeneration: (
    input: MultiStageGenerationInput,
  ) => Promise<MultiStageGenerationResponse>;
  uploadCustomModelVirtualTryOnInputToStorage: (
    args: UploadCustomModelVirtualTryOnInputToStorageArgs,
  ) => Promise<UploadCustomModelVirtualTryOnInputToStorageResponse>;
  customModelVirtualTryOn: (args: TryOnInput) => Promise<TryOnResponse>;
  uploadCustomModelAssetToStorage: (
    args: UploadCustomModelAssetToStorageArgs,
  ) => Promise<UploadCustomModelAssetToStorageResponse>;
  updateCustomModelDatasetItemThumbnail: (
    args: UpdateCustomModelDatasetItemThumbnailArgs,
  ) => Promise<UpdateCustomModelDatasetItemThumbnailResponse>;
  autoCorrectCustomModelCaption: (
    args: AutoCorrectCustomModelCaptionArgs,
  ) => Promise<AutoCorrectCustomModelCaptionResponse>;
  appendTextToPrompt: (args: AppendTextToPromptArgs) => Promise<AppendTextToPromptResponse>;
  removeTextFromPrompt: (args: RemoveTextFromPromptArgs) => Promise<RemoveTextFromPromptResponse>;
  sendEmailRedirectLink: (params: {
    email: string;
    name?: string;
    noAuthToken?: boolean;
  }) => Promise<HttpsCallableResult<void | string>> | undefined;
  sendEmailLoginLink: (params: {
    email: string;
    name?: string;
    token?: string;
  }) => Promise<{ success: boolean; message?: string }>;
  warpParsedClothImage: (params: {
    clothImageUrl: string;
    parsedClothMaskImageUrl: string;
    personImageId?: string;
    epsilon?: number;
    leftColor?: string;
    middleColor?: string;
    rightColor?: string;
  }) => Promise<WarpParsedImageResult | undefined>;
  parseClothImage: (params: {
    imageUrl: string;
    personImageId?: string;
    useClothAlpha?: boolean;
    epsilon?: number;
    leftColor?: string;
    middleColor?: string;
    rightColor?: string;
  }) => Promise<ParseClothImageResult | undefined>;
  renderClothImages: (
    renderArgs: RenderClothImageArgs,
  ) => Promise<RenderClothImageResult | undefined>;
  getImageCaption: (args: ImageCaptionArgs) => Promise<string>;
  getTryOnModelPreview: (args: {
    imageId: string;
  }) => Promise<TryOnModelPreviewData | undefined> | undefined;
  getTryOnModelPreviewsGenerator: (args: { batchSize: number }) => TryOnPreviewGenerator;
  getMaskImageBoundingBox: (args: {
    imageUrl: string;
  }) => Promise<ObjectBounds2d | undefined> | undefined;
  getImageText: (args: {
    imageUrl: string | string[];
    texts: string[];
  }) => Promise<string | undefined> | undefined;
  getImageMultiText: (args: {
    imageUrl: string | string[];
    texts: string[][];
  }) => Promise<string[] | undefined> | undefined;
  onUserApiDataUpdate: (callback: (userId?: UserApiDataDoc) => void) => () => void;
  getApiUsageGenerator: (
    args: GetApiUsageGeneratorArgs,
  ) => DocsBatchGenerator<ApiUsageDoc> | undefined;
  onApiUsageUpdate: (args: OnApiUsageUpdateArgs) => () => void;
  getEmailApiData: (email: string) => Promise<EmailApiDataDoc>;
  getFirestoreTemplate: (id: string) => Promise<GenerateTemplateItem | undefined>;
  getFirestoreTemplatesByTag: (tag: string) => Promise<GenerateTemplateItem[]>;
  setFirestoreTemplatesByTagNextBatch: (tag: string, pageSize: number) => Promise<boolean>;
  getDefaultGenerateTemplates: () => Promise<GenerateTemplateTypesenseDoc[]>;
  getGenerateTemplateGenerator: (props: { batchSize?: number }) => FlairTemplateGenerator;
  getUserInvoices: (params: StripeListInvoicesParams) => Promise<StripeListInvoicesResponse>;
  downloadAndUploadInvoice: (invoiceId: string) => Promise<string | undefined>;
  updateStripeSubscription: (
    params: UpdateStripeSusbcriptionArgs,
  ) => Promise<UpdateStripeSusbcriptionResponse>;
  disconnectRealTimeState: (connectionId: string) => Promise<void> | undefined;
  getTagsInOrder: (projectType?: UserProjectType) => Promise<string[]>;
  getElementsManager: () => ElementsSearchManager;
  getUserFeatureFlags: (userId: string) => Promise<BackendUserFeatureFlags>;

  // Color correct v2
  startColorCorrectV2: (
    args: ColorCorrectV2Args & {
      renderProcessController: RenderProcessController;
    },
  ) => Promise<ColorCorrectV2Response>;
  onColorCorrectV2Update: (args: OnColorCorrectV2UpdateArgs) => () => void;
  cancelColorCorrectV2Job: (args: CancelColorCorrectV2JobArgs) => Promise<void>;
  outpaintImage: (args: OutpaintImageArgs) => Promise<OutpaintImageResponse>;

  // Video generation
  onVideoGenerationDocUpdate: (args: OnVideoGenerationDocUpdateArgs) => () => void;
  generateVideo: (request: VideoGenerationRequest) => Promise<VideoGenerationResponse>;
  getVideoGenerationDoc: (generationId: string) => Promise<VideoGenerationDoc | undefined>;
  onUserVideoGenerationsUpdate: (args: OnUserVideoGenerationsUpdateArgs) => () => void;
  uploadVideoKeyFrameToStorage: (
    args: UploadVideoKeyFrameToStorageArgs,
  ) => Promise<UploadVideoKeyFrameToStorageResponse>;
  generateVideoPrompt: (args: GenerateVideoPromptArgs) => Promise<GenerateVideoPromptResponse>;
  shortenCaption: (args: ShortenCaptionArgs) => Promise<ShortenCaptionResponse>;
  setSubscriptionProductIdQuota(
    params: SetSubscriptionProductIdQuotaParams,
  ): Promise<SetSubscriptionProductIdQuotaResponse>;
  listAllProducts(): Promise<StripeProduct[]>;
  onProductsUpdate(callback: (products: StripeProduct[]) => void): () => void;
  listAllPrices(productId: string): Promise<StripePrice[]>;
  onPricesUpdate(productId: string, callback: (prices: StripePrice[]) => void): () => void;
  createStripePortalLink: (
    params: CreateStripePortalLinkParams,
  ) => Promise<CreateStripePortalLinkResponse>;
  uploadReferenceHumanFile: (
    args: UploadReferenceHumanFileArgs,
  ) => Promise<UploadReferenceHumanResponse>;
  getReferenceHumanGenerator: (
    args: GetReferenceHumanGeneratorArgs,
  ) => GetReferenceHumanGeneratorResponse;
  callAnyLanguageModel: (request: FalAnyLLMArgs) => Promise<FalAnyLLMResponse>;

  // Teams
  getUserTeams: (publicUserId: PublicUserId) => Promise<TeamMetadataCollection>;
  getTeamMetadata: (teamId: PublicTeamId) => Promise<TeamMetadata | undefined>;
  onUserTeamsUpdate: (args: OnUserTeamsUpdateArgs) => () => void;
  onPublicTeamQuotasUpdate: (args: OnPublicTeamQuotasUpdateArgs) => () => void;
  inviteUsersToTeam: (args: InviteUsersToTeamArgs) => Promise<InviteUsersToTeamResponse[]>;
  removeUserFromTeam: (args: RemoveUserFromTeamArgs) => Promise<RemoveUserFromTeamResponse>;
  getPublicUserMetadata: (publicUserId: PublicUserId) => Promise<PublicUserMetadata | undefined>;
  updateUserTeamRole: (args: UpdateUserTeamRoleArgs) => Promise<UpdateUserTeamRoleResponse>;
  updateUserTeamName: (args: UpdateUserTeamNameArgs) => Promise<UpdateUserTeamNameResponse>;
  buildAHuman: (args: BuildAHumanArgs) => Promise<BuildAHumanResponse>;
}

import { type ImgHTMLAttributes, forwardRef } from "react";

export const BaseImage = forwardRef<
  HTMLImageElement,
  ImgHTMLAttributes<HTMLImageElement> & {
    imgProps?: Omit<
      ImgHTMLAttributes<HTMLImageElement>,
      | "src"
      | "alt"
      | "className"
      | "width"
      | "height"
      | "style"
      | "loading"
      | "fetchpriority"
      | "decoding"
      | "ref"
    >;
  }
>(
  (
    {
      src,
      alt,
      className,
      width,
      height,
      style,
      loading = "eager",
      fetchPriority = "high",
      decoding = "async",
      imgProps,
      ...rest
    },
    ref,
  ) => {
    return (
      <img
        ref={ref}
        src={src}
        alt={alt}
        className={className}
        width={width}
        height={height}
        style={style}
        loading={loading}
        fetchPriority={fetchPriority}
        decoding={decoding}
        {...imgProps}
        {...rest}
      />
    );
  },
);

BaseImage.displayName = "BaseImage";

import * as Sentry from "@sentry/react";
import { PostHogProvider } from "posthog-js/react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
//https://vite.dev/guide/build#load-error-handling
window.addEventListener("vite:preloadError", (_e) => {
  window.location.reload();
});

if (import.meta.env.VITE_APP_ENV === "production") {
  Sentry.init({
    environment: process.env.NODE_ENV ?? "development",
    dsn: "https://0eb1d3c8893f7a1eb418d0477bb29570@o4508263304921088.ingest.us.sentry.io/4508263306559488",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.replayCanvasIntegration(),
    ],

    // Tracing
    tracesSampleRate: process.env.NODE_ENV === "production" ? 0.05 : 0, //  Capture 5% of the react apps transactions
    //this disables distributed tracing - unless we can put firebase behind a proxy this is not useful
    tracePropagationTargets: [],
    // Session Replay
    replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.05 : 0, // This sets the sample rate at 5%.
    replaysOnErrorSampleRate: process.env.NODE_ENV === "production" ? 1.0 : 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <PostHogProvider
    apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
    options={{
      api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
      capture_pageview: false, //we use ph-pageviews.tsx to track pageviews
      capture_pageleave: false,
    }}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </PostHogProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

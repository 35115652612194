import {
  CustomModelType,
  FrontendDisplayTemplateType,
  ToolType,
  UserProjectType,
} from "@/core/common/types";
import { FlameIcon } from "lucide-react";

export function getProjectDashboardCarouselTemplates(): Template[] {
  return Templates.filter((template) =>
    template.categories.includes(TemplateCategories.ProjectDashboardCarousel),
  );
}

export function isProjectDashboardCarouselTemplate(projectType: UserProjectType): boolean {
  return Templates.find((template) => template.data?.projectWorkflow === projectType) !== undefined;
}

export function getProjectDashboardCarouselTemplateUntitledName(
  projectType: UserProjectType,
): string {
  return (
    Templates.find((template) => template.data?.projectWorkflow === projectType)?.untitledName || ""
  );
}

export const UserProjectTypeDefaultThumbnailUrl: Record<UserProjectType, string> = {
  [UserProjectType.ProductPhotography]:
    "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/2932968e-b561-4421-a8c3-713fcec53e00/1024",
  [UserProjectType.TryOn]:
    "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3c92c366-07f6-49b3-2e3b-ff263c4d4700/1024",
  [UserProjectType.Accessories]:
    "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/443837f4-905d-4a93-d1f7-d1f6a5fb5600/1024",
  [UserProjectType.Beauty]:
    "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/aaf93338-d757-48fd-bff4-3d73cab3ae00/1024",
  [UserProjectType.CPG]:
    "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e2d6fda3-ae3e-469f-022c-b9915a206b00/1024",
  [UserProjectType.Fashion]:
    "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/cca368d6-9698-48fe-37ab-525ea03b3d00/1024",
  [UserProjectType.Food]:
    "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/be7819d2-e9f2-41dd-168e-dcab02604000/1024",
  [UserProjectType.Furniture]:
    "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/71594984-2473-49df-a624-70659c184a00/1024",
  [UserProjectType.Homegoods]:
    "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/130d61f7-8526-4e2b-a85f-79acf238e100/1024",
  [UserProjectType.Humans]:
    "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/36797430-2f1b-44ef-2d83-4692f1602c00/1024",
  [UserProjectType.CustomModels]:
    "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3c92c366-07f6-49b3-2e3b-ff263c4d4700/1024",
  [UserProjectType.Staging]:
    "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e0efac7d-10ff-4fc8-5f68-9d08e5b08c00/1024",
};

export type PhotoShootTool = {
  image: string;
  title: string;
  description: string;
  benefits: string[];
  recommended: boolean;
  type: ToolType;
};

export const PhotoshootTools: PhotoShootTool[] = [
  {
    // Custom Models
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/14bd9529-af51-4bf8-37a5-d7c6b1dafe00/1024",
    title: "Custom Models",
    description: "Better for human models, wearables and scenes",
    benefits: [
      "You want to include a human model or animal in your generation",
      "You have many images of your object",
      "You require multiple images with different angles",
    ],
    recommended: true,
    type: ToolType.CustomModel,
  },
  {
    // Drag & Drop Canvas
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/6a3d242c-c230-497e-5277-0339bfdabb00/1024",
    title: "Drag & Drop Canvas",
    description: "Better for logos, small details and objects",
    benefits: [
      "You have a logo/small details you want to include",
      "You have 1 - 2 images of your object",
      "You want a lot of control over the outcome of your output",
    ],
    recommended: false,
    type: ToolType.DragAndDropCanvas,
  },
];

export enum TemplateCategories {
  Product = "Product",
  Fashion = "Fashion",
  Brand = "Brand",
  Popular = "Popular",
  ProjectDashboardCarousel = "ProjectDashboardCarousel",
}

export const TemplateCategoryIcons: Partial<Record<TemplateCategories, typeof FlameIcon>> = {
  [TemplateCategories.Popular]: FlameIcon,
} as const;

export const TemplateCategoryNamesAndOrder: Record<
  TemplateCategories,
  { name: string; description: string; showInTemplatesDashboard: boolean }
> = {
  [TemplateCategories.Popular]: {
    name: "Most Popular Templates",
    description: "Our most used templates across categories",
    showInTemplatesDashboard: true,
  },
  [TemplateCategories.Fashion]: {
    name: "Fashion & Model Templates",
    description: "Templates designed for items displayed on or with human models",
    showInTemplatesDashboard: true,
  },
  [TemplateCategories.Product]: {
    name: "Product Templates",
    description: "Styled templates for items staged in a scene with background props",
    showInTemplatesDashboard: true,
  },
  [TemplateCategories.Brand]: {
    name: "Brand Design",
    description: "",
    showInTemplatesDashboard: false,
  },
  [TemplateCategories.ProjectDashboardCarousel]: {
    name: "Project Dashboard Carousel",
    description: "Templates designed for items displayed on the project dashboard",
    showInTemplatesDashboard: false,
  },
};

export enum TemplateType {
  Project = "Project",
  ProjectWorkflow = "ProjectWorkflow",
  CustomModel = "CustomModel",
  CustomModelWorkflow = "CustomModelWorkflow",
  CustomModelExample = "CustomModelExample",
  CustomModelPlayground = "CustomModelPlayground",
  Video = "Video",
}

export type Template = {
  name: string;
  image: string;
  tooltipText: string;
  untitledName: string;
  type: TemplateType;
  categories: TemplateCategories[];
  data?: {
    modelId?: string;
    projectWorkflow?: UserProjectType;
    customModelWorkflow?: FrontendDisplayTemplateType | CustomModelType;
    customModelExampleId?: string;
  };
};

export const Templates: Template[] = [
  // POPULAR
  {
    name: "All Fashion",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/06820587-5cf5-4bfb-a453-91c96ead2700/1024",
    tooltipText: "Create a project with a human model",
    untitledName: "Untitled Fashion Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Popular],
    data: {
      customModelWorkflow: CustomModelType.Fashion,
    },
  },
  {
    name: "Beauty",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/0bf8e681-88e4-47b5-d7f6-f7be83b8a500/1024",
    tooltipText: "Create a project with beauty products",
    untitledName: "Untitled Beauty Project",
    type: TemplateType.ProjectWorkflow,
    categories: [TemplateCategories.Popular],
    data: {
      projectWorkflow: UserProjectType.Beauty,
    },
  },
  {
    name: "Consumer Packaged Goods",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/62a15702-706c-4ba4-b95b-571b487bf500/1024",
    tooltipText: "Create a project with consumer packaged goods",
    untitledName: "Untitled Consumer Packaged Goods Project",
    type: TemplateType.ProjectWorkflow,
    categories: [TemplateCategories.Popular],
    data: {
      projectWorkflow: UserProjectType.CPG,
    },
  },
  {
    name: "Jewelry & Accessories",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/77c9233d-1bb5-41dd-805e-0bf15868cf00/1024",
    tooltipText: "Create a project with jewelry and accessories",
    untitledName: "Untitled Jewelry & Accessories Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Popular],
    data: {
      customModelWorkflow: CustomModelType.Jewelry,
    },
  },
  {
    name: "Custom Brand Style",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/749f51d4-6bd8-42c3-de4f-bb2e29e9be00/1024",
    tooltipText: "Create a style inspired by your brand aesthetic",
    untitledName: "Untitled Style Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Popular],
    data: {
      customModelWorkflow: CustomModelType.Style,
    },
  },
  // PRODUCT
  {
    name: "Beauty",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1c7d93a1-34d8-400e-0949-f84f8d9d6800/1024 ",
    tooltipText: "Create a project with beauty products",
    untitledName: "Untitled Beauty Project",
    type: TemplateType.ProjectWorkflow,
    categories: [TemplateCategories.Product],
    data: {
      projectWorkflow: UserProjectType.Beauty,
    },
  },
  {
    name: "Furniture",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/881cb760-9035-4df0-40c4-6e1819cd3e00/1024",
    tooltipText: "Create a project with furniture",
    untitledName: "Untitled Furniture Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Product],
    data: {
      projectWorkflow: UserProjectType.Furniture,
      customModelWorkflow: CustomModelType.Furniture,
    },
  },
  {
    name: "Technology",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/02fe7e7b-bc83-4309-521f-e8624a47e500/1024",
    tooltipText: "Create a project with technology",
    untitledName: "Untitled Technology Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Product],
    data: {
      customModelWorkflow: CustomModelType.Tech,
    },
  },
  {
    name: "Food",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1cf4fae7-736a-4f7b-30b4-bf4157bb3000/1024",
    tooltipText: "Create a project with food",
    untitledName: "Untitled Food Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Product],
    data: {
      customModelWorkflow: CustomModelType.Food,
    },
  },
  {
    name: "Home Decor",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/73c51e30-5c43-4f8f-6ed2-fd1b11953f00/1024",
    tooltipText: "Create a project with home decor",
    untitledName: "Untitled Home Decor Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Product],
    data: {
      customModelWorkflow: FrontendDisplayTemplateType.HomeDecor,
    },
  },
  {
    name: "Vehicles",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/197750e8-c413-4710-bd5f-152e4d1fa800/1024",
    tooltipText: "Create a project with vehicles",
    untitledName: "Untitled Vehicles Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Product],
    data: {
      customModelWorkflow: FrontendDisplayTemplateType.Vehicles,
    },
  },
  {
    name: "Kitchenware",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9dc53736-fbfc-4108-f52c-f26de1973800/1024",
    tooltipText: "Create a project with kitchenware",
    untitledName: "Untitled Kitchenware Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Product],
    data: {
      customModelWorkflow: FrontendDisplayTemplateType.Kitchenware,
    },
  },
  {
    name: "Consumer Packaged Goods",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d2c01f55-75b1-449f-a4dc-d212eeb11f00/1024",
    tooltipText: "Create a project with consumer packaged goods",
    untitledName: "Untitled Consumer Packaged Goods Project",
    type: TemplateType.ProjectWorkflow,
    categories: [TemplateCategories.Product],
    data: {
      projectWorkflow: UserProjectType.CPG,
    },
  },
  {
    name: "Toys and Games",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b9974c02-a9fd-4e29-d264-5941fce0f800/1024",
    tooltipText: "Create a project with toys and games",
    untitledName: "Untitled Toys and Games Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Product],
    data: {
      customModelWorkflow: FrontendDisplayTemplateType.Toys,
    },
  },
  {
    name: "Office Supplies",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/4134e395-cc99-479d-3fa0-92ab3e8eb000/1024",
    tooltipText: "Create a project with office supplies",
    untitledName: "Untitled Office Supplies Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Product],
    data: {
      customModelWorkflow: FrontendDisplayTemplateType.OfficeSupplies,
    },
  },
  // FASHION & MODELS
  {
    name: "Hoodies & Sweaters",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/11905452-6307-408b-7068-cb4e75721300/1024",
    tooltipText: "Create a project with hoodies and sweaters",
    untitledName: "Untitled Hoodies & Sweaters Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Fashion],
    data: {
      customModelWorkflow: FrontendDisplayTemplateType.Sweaters,
    },
  },
  {
    name: "Hats",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ac9aa951-4369-4e56-96a3-da681dc79b00/1024",
    tooltipText: "Create a project with hats",
    untitledName: "Untitled Hats Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Fashion],
    data: {
      customModelWorkflow: FrontendDisplayTemplateType.Hats,
    },
  },
  {
    name: "Footwear",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1fb13f64-30af-4c7d-3950-3fa18730ce00/1024",
    tooltipText: "Create a project with footwear",
    untitledName: "Untitled Footwear Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Fashion],
    data: {
      customModelWorkflow: CustomModelType.Footwear,
    },
  },
  {
    name: "Glasses",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/b0e9db01-ff94-42fb-230b-0e83867b6900/1024",
    tooltipText: "Create a project with glasses",
    untitledName: "Untitled Glasses Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Fashion],
    data: {
      customModelWorkflow: FrontendDisplayTemplateType.Glasses,
    },
  },
  {
    name: "Men's Fashion",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e02dfadf-b5a4-4adc-76d9-0ae90f505400/1024",
    tooltipText: "Create a project with men's fashion",
    untitledName: "Untitled Men's Fashion Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Fashion],
    data: {
      customModelWorkflow: FrontendDisplayTemplateType.MensFashion,
    },
  },
  {
    name: "Bags & Purses",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/5fbbd38b-f680-4901-634a-d4f73f6e2e00/1024",
    tooltipText: "Create a project with bags and purses",
    untitledName: "Untitled Bags & Purses Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Fashion],
    data: {
      customModelWorkflow: CustomModelType.Bags,
    },
  },
  {
    name: "Watches",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/18f6b6e6-7db2-483e-5d19-056afb239300/1024",
    tooltipText: "Create a project with watches",
    untitledName: "Untitled Watches Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Fashion],
    data: {
      customModelWorkflow: FrontendDisplayTemplateType.Watches,
    },
  },
  {
    name: "Human Model",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/e1693755-2fb2-4b53-37d3-61061c3b2d00/1024",
    tooltipText: "Create a project with a human model",
    untitledName: "Untitled Human Model Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Fashion],
    data: {
      customModelWorkflow: CustomModelType.VirtualModel,
    },
  },
  {
    name: "Jewelry & Accessories",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/ab8afae4-f9ec-4f10-a8a1-4a0c024f8b00/1024",
    tooltipText: "Create a project with jewelry and accessories",
    untitledName: "Untitled Jewelry & Accessories Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Fashion],
    data: {
      customModelWorkflow: CustomModelType.Jewelry,
    },
  },
  {
    name: "Dresses",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/7fe052de-bb74-4caa-cc6c-3fcc14c7cf00/1024",
    tooltipText: "Create a project with dresses",
    untitledName: "Untitled Dresses Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Fashion],
    data: {
      customModelWorkflow: FrontendDisplayTemplateType.Dresses,
    },
  },
  {
    name: "Outerwear",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/3186fc0c-3343-4bd8-7094-d25cd2470800/1024",
    tooltipText: "Create a project with outerwear",
    untitledName: "Untitled Outerwear Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Fashion],
    data: {
      customModelWorkflow: FrontendDisplayTemplateType.Outerwear,
    },
  },
  {
    name: "All Fashion",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9bae9484-ba6e-4049-9d4a-191d07165e00/1024",
    tooltipText: "Create a project with all fashion",
    untitledName: "Untitled All Fashion Project",
    type: TemplateType.CustomModelWorkflow,
    categories: [TemplateCategories.Fashion],
    data: {
      customModelWorkflow: CustomModelType.Fashion,
    },
  },

  // SHOWN ON THE PROJECT DASHBOARD CAROUSEL
  {
    name: "Beauty",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1c7d93a1-34d8-400e-0949-f84f8d9d6800/1024 ",
    tooltipText: "Create a project with beauty products",
    untitledName: "Untitled Beauty Project",
    type: TemplateType.ProjectWorkflow,
    categories: [TemplateCategories.ProjectDashboardCarousel],
    data: {
      projectWorkflow: UserProjectType.Beauty,
    },
  },
  {
    name: "Consumer Packaged Goods",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/d2c01f55-75b1-449f-a4dc-d212eeb11f00/1024",
    tooltipText: "Create a project with consumer packaged goods",
    untitledName: "Untitled Consumer Packaged Goods Project",
    type: TemplateType.ProjectWorkflow,
    categories: [TemplateCategories.ProjectDashboardCarousel],
    data: {
      projectWorkflow: UserProjectType.CPG,
    },
  },
  {
    name: "Food",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/1cf4fae7-736a-4f7b-30b4-bf4157bb3000/1024",
    tooltipText: "Create a project with food",
    untitledName: "Untitled Food Project",
    type: TemplateType.ProjectWorkflow,
    categories: [TemplateCategories.ProjectDashboardCarousel],
    data: {
      projectWorkflow: UserProjectType.Food,
    },
  },
  {
    name: "Furniture",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/881cb760-9035-4df0-40c4-6e1819cd3e00/1024",
    tooltipText: "Create a project with furniture",
    untitledName: "Untitled Furniture Project",
    type: TemplateType.ProjectWorkflow,
    categories: [TemplateCategories.ProjectDashboardCarousel],
    data: {
      projectWorkflow: UserProjectType.Furniture,
    },
  },
  {
    name: "Home Decor",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/73c51e30-5c43-4f8f-6ed2-fd1b11953f00/1024",
    tooltipText: "Create a project with home decor",
    untitledName: "Untitled Home Decor Project",
    type: TemplateType.ProjectWorkflow,
    categories: [TemplateCategories.ProjectDashboardCarousel],
    data: {
      projectWorkflow: UserProjectType.Homegoods,
    },
  },
  /*
  {
    name: 'Brand Design Case 1',
    image: 'https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/de3af3df-bafe-428d-ce77-d2b06fa58500/1024',
    type: TemplateType.CustomModelWorkflow,
    category: TemplateCategories.Brand,
    data: {
      customModelWorkflow: CustomModelType.BrandA,
    }
  },

  {
    // custom model template
    name: "Brand Design Case 2",
    image:
      "https://flair.ai/cdn-cgi/imagedelivery/i1XPW6iC_chU01_6tBPo8Q/9505ad2e-c91d-4c28-3b13-4477ca4d2300/1024",
    type: TemplateType.CustomModelWorkflow,
    category: TemplateCategories.Brand,
    data: {
      customModelWorkflow: CustomModelType.BrandB,
    }
  },
  */
];

import {
  AppendTextToPromptArgs,
  AppendTextToPromptResponse,
  AutoCorrectCustomModelCaptionArgs,
  AutoCorrectCustomModelCaptionResponse,
  CreateCustomModelArgs,
  DeleteCustomModelArgs,
  DeleteCustomModelPredictionArgs,
  DeleteCustomModelPredictionResponse,
  DeleteCustomModelResponse,
  FixCustomModelDetailsCorrespondenceInput,
  FixCustomModelDetailsCorrespondenceOutput,
  GetCustomModelTrainingArgs,
  GetCustomModelTrainingsArgs,
  GetCustomModelTrainingsResponse,
  GetPublicCustomModelPredictionsArgs,
  GetPublicCustomModelPredictionsResponse,
  isFixCustomModelDetailsCorrespondenceOutput,
  OnCustomModelPredictionsUpdateArgs,
  OnCustomModelPredictionUpdateArgs,
  OnCustomModelTrainingCollectionUpdateArgs,
  OnCustomModelTrainingUpdateArgs,
  OnUserCustomModelsUpdateArgs,
  RemoveTextFromPromptArgs,
  RemoveTextFromPromptResponse,
  StartCustomModelPredictionArgs,
  StartCustomModelTrainingArgs,
  StopCustomModelPredictionArgs,
  StopCustomModelTrainingArgs,
  UpdateCustomModelDatasetItemThumbnailArgs,
  UpdateCustomModelDatasetItemThumbnailResponse,
  UpdateCustomModelInfoArgs,
  UploadCustomModelAssetToStorageArgs,
  UploadCustomModelAssetToStorageResponse,
  UploadCustomModelDataItemToStorageArgs,
  UploadCustomModelDataItemToStorageResponse,
  UploadCustomModelVirtualTryOnInputToStorageArgs,
  UploadCustomModelVirtualTryOnInputToStorageResponse,
} from "@/backend/base";
import {
  ClarityUpscaleInput,
  ClarityUpscaleResponse,
  CustomModelPostProcessInput,
  CustomModelPostProcessResponse,
  FaceUpscalerInput,
  FaceUpscalerResponse,
  FixProductDetailsInput,
  FixProductDetailsResponse,
  GetMaskFromPromptInput,
  GetMaskFromPromptResponse,
  InContextVariationsInput,
  InContextVariationsResponse,
  MultiStageGenerationInput,
  MultiStageGenerationResponse,
  SwapFaceInput,
  SwapFaceResponse,
  TryOnInput,
  TryOnResponse,
} from "@/backend/custom-model-post-process";
import { FirebaseBackend } from "@/backend/firebase/firebase-backend";
import {
  appendPromptInstructions,
  removePromptInstructions,
} from "@/components/custom-model/custom-model-prompt-instructions";
import { extractModifiedPrompt } from "@/components/custom-model/custom-model-prompt-utils";
import { editorContextStore } from "@/contexts/editor-context";
import {
  AppRoleType,
  PublicUserId,
  UiDisplayMessageDialogEventHandler,
  UserAssetType,
} from "@/core/common/types";
import {
  correctCustomModelScaleConfigs,
  CustomModelAction,
  CustomModelDataset,
  CustomModelDatasetItem,
  CustomModelInfo,
  CustomModelPredictionInput,
  CustomModelPredictionInputBackendType,
  CustomModelPredictionInputFal,
  CustomModelPredictionInputSelfHost,
  CustomModelPredictionItem,
  CustomModelTrainingItem,
  HandleCreateCustomModelArgs,
  HandleCreateCustomModelResponse,
  HandleCustomModelTrainingStartArgs,
  HandleCustomModelTrainingStartResponse,
  HandleCustomModelTrainingStopArgs,
  HandleCustomModelTrainingStopResponse,
  HandleStartCustomModelPredictionArgs,
  HandleStartCustomModelPredictionResponse,
  HandleStopCustomModelPredictionArgs,
  HandleStopCustomModelPredictionResponse,
  isCustomModelDatasetItem,
  isCustomModelInfo,
  isCustomModelPredictionItem,
  isCustomModelTrainingItem,
} from "@/core/common/types/custom-model-types";
import { DocVisibility } from "@/core/common/types/doc-visibility";
import { isPublicTeamId, PublicTeamId } from "@/core/common/types/team";
import {
  canUserCreateCustomModel,
  canUserRunCustomModelPostProcessing,
  CanUserRunCustomModelPostProcessingResponseNotAllowed,
  canUserStartPrediction,
  canUserStartTraining,
} from "@/core/utils/custom-model-utils";
import { removeUndefinedFromObject } from "@/core/utils/object-utils";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { canUserUploadAsset } from "@/core/utils/quota-utils";
import { extractTripleTickContent, isDataURL, isValidHttpsUrl } from "@/core/utils/string-utils";
import { getCurrentTeamId } from "@/core/utils/team-utils";
import { isRoleTeamMember } from "@/core/utils/user-role-utils";
import { getUserQuotasFromEditorContext } from "@/hooks/use-user-quotas";
import debounce from "debounce-promise";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { Functions, httpsCallable, HttpsCallable } from "firebase/functions";
import {
  createGenerateUserAssetUploadUrlFunction,
  GenerateUserAssetUploadUrlArgs,
  GenerateUserAssetUploadUrlFunction,
  GenerateUserAssetUploadUrlResponse,
} from "./asset-upload-utils";
import { ChatWithImagesFunction, createChatWithImagesFunction } from "./chat-with-images-utils";
import {
  AUTO_CORRECT_CUSTOM_MODEL_CAPTION,
  CUSTOM_MODEL_ACTION_ENTRYPOINT,
  FIX_CUSTOM_MODEL_DETAILS_CORRESPONDENCE,
  UPDATE_CUSTOM_MODEL_DATASET_ITEM_THUMBNAIL,
} from "./firebase-function-name";

export const customModelCollectionName = "customModelsV2";
export const customModelDatasetCollectionName = "dataset";
export const customModelTrainingCollectionName = "training";
export const customModelPredictionCollectionName = "customModelsV2Predictions";

export function getCustomModelCollectionRef(firestore: Firestore) {
  return collection(firestore, customModelCollectionName);
}

export function getCustomModelDocRef({
  firestore,
  modelId,
}: {
  firestore: Firestore;
  modelId: string;
}) {
  return doc(getCustomModelCollectionRef(firestore), modelId);
}

export function getCustomModelDatasetCollectionRef({
  firestore,
  modelId,
}: {
  firestore: Firestore;
  modelId: string;
}) {
  return collection(getCustomModelDocRef({ firestore, modelId }), customModelDatasetCollectionName);
}

export function getCustomModelDatasetItemDocRef({
  firestore,
  modelId,
  dataId,
}: {
  firestore: Firestore;
  modelId: string;
  dataId: string;
}) {
  return doc(
    getCustomModelDatasetCollectionRef({
      firestore,
      modelId,
    }),
    dataId,
  );
}

export function getCustomModelTrainingCollectionRef({
  firestore,
  modelId,
}: {
  firestore: Firestore;
  modelId: string;
}) {
  return collection(
    getCustomModelDocRef({ firestore, modelId }),
    customModelTrainingCollectionName,
  );
}

export function getCustomModelTrainingDocRef({
  firestore,
  modelId,
  trainingId,
}: {
  firestore: Firestore;
  modelId: string;
  trainingId: string;
}) {
  return doc(
    getCustomModelTrainingCollectionRef({
      firestore,
      modelId,
    }),
    trainingId,
  );
}

function getCustomModelPredictionCollectionRef({ firestore }: { firestore: Firestore }) {
  return collection(firestore, customModelPredictionCollectionName);
}

function getCustomModelPredictionDocRef({
  firestore,
  predictionId,
}: {
  firestore: Firestore;
  predictionId: string;
}) {
  return doc(
    getCustomModelPredictionCollectionRef({
      firestore,
    }),
    predictionId,
  );
}

function getStoragePathFromSignedUrl(signedUrl: string): string {
  try {
    const url = new URL(signedUrl);
    // Assuming the file path is the pathname component of the URL
    const prefix = `/${import.meta.env.VITE_FIREBASE_STORAGE_BUCKET}/`;

    const pathname = url.pathname;

    if (pathname.startsWith(prefix)) {
      return pathname.slice(prefix.length);
    }

    return pathname;
  } catch (error) {
    console.error("Invalid URL:", error);
    return ""; // Return an empty string in case of an invalid URL
  }
}

async function getUploadDataFromString(body: string) {
  if (isDataURL(body) || isValidHttpsUrl(body)) {
    const response = await fetch(body);
    if (!response.ok) {
      throw new Error(`Failed to fetch data from URI. Status: ${response.status}`);
    }
    return await response.blob();
  }
  return body;
}

/**
 * Uploads a string to cloud storage using a signed URL and sets custom metadata.
 *
 * @param signedUrl The signed URL for the upload.
 * @param content The string content to upload.
 * @param metadata An object containing metadata key-value pairs.
 * @returns A promise that resolves with the HTTP response.
 */
async function uploadDataWithMetadata(
  signedUrl: string,
  body: string | File | Blob,
  contentType: string,
  metadata: Record<string, string>,
): Promise<Response> {
  if (!body) {
    throw new Error("Upload body is empty or undefined");
  }

  try {
    let uploadBody = body;
    if (typeof body === "string" && !isDataURL(body) && !isValidHttpsUrl(body)) {
      uploadBody = new Blob([body], { type: contentType });
    }

    const response = await fetch(signedUrl, {
      method: "PUT",
      headers: {
        ...metadata,
      },
      body: uploadBody,
      credentials: "omit",
      mode: "cors",
    });

    if (!response.ok) {
      const errorText = await response.text();
      debugError(`Upload failed with status: ${response.status} - ${errorText}`);
      throw new Error(`Upload failed with status: ${response.status} - ${errorText}`);
    }

    return response;
  } catch (error) {
    console.error("Error uploading data with metadata:", error);
    throw error;
  }
}

export class CustomModelManager {
  private firestore: Firestore;

  private backend: FirebaseBackend;

  private chatWithImages: ChatWithImagesFunction;

  private cachedPublicCustomModels: CustomModelInfo[] = [];

  private customModelActionEntrypoint: HttpsCallable<
    | HandleCreateCustomModelArgs
    | HandleCustomModelTrainingStartArgs
    | HandleCustomModelTrainingStopArgs
    | HandleStartCustomModelPredictionArgs
    | HandleStopCustomModelPredictionArgs,
    | HandleCreateCustomModelResponse
    | HandleCustomModelTrainingStartResponse
    | HandleCustomModelTrainingStopResponse
    | HandleStartCustomModelPredictionResponse
    | HandleStopCustomModelPredictionResponse
  >;

  private fixCustomModelDetailsCorrespondence: HttpsCallable<
    FixCustomModelDetailsCorrespondenceInput,
    FixCustomModelDetailsCorrespondenceOutput
  >;

  private generateUserAssetUploadUrlColabJuly24: GenerateUserAssetUploadUrlFunction;

  private autoCorrectCustomModelCaptionColabJuly24: HttpsCallable<
    AutoCorrectCustomModelCaptionArgs,
    AutoCorrectCustomModelCaptionResponse
  >;

  private updateCustomModelDatasetItemThumbnailColabJuly24: HttpsCallable<
    UpdateCustomModelDatasetItemThumbnailArgs,
    UpdateCustomModelDatasetItemThumbnailResponse
  >;

  constructor({
    firestore,
    firebaseFunctions,
    backend,
  }: {
    firestore: Firestore;
    firebaseFunctions: Functions;
    backend: FirebaseBackend;
  }) {
    this.firestore = firestore;

    this.backend = backend;

    this.customModelActionEntrypoint = httpsCallable(
      firebaseFunctions,
      CUSTOM_MODEL_ACTION_ENTRYPOINT,
    );

    this.fixCustomModelDetailsCorrespondence = httpsCallable(
      firebaseFunctions,
      FIX_CUSTOM_MODEL_DETAILS_CORRESPONDENCE,
    );

    this.autoCorrectCustomModelCaptionColabJuly24 = httpsCallable(
      firebaseFunctions,
      AUTO_CORRECT_CUSTOM_MODEL_CAPTION,
    );

    this.updateCustomModelDatasetItemThumbnailColabJuly24 = httpsCallable(
      firebaseFunctions,
      UPDATE_CUSTOM_MODEL_DATASET_ITEM_THUMBNAIL,
    );

    this.generateUserAssetUploadUrlColabJuly24 = createGenerateUserAssetUploadUrlFunction({
      firebaseFunctions,
    });

    this.chatWithImages = createChatWithImagesFunction({
      firebaseFunctions,
    });
  }

  private getCustomModelCollectionRef() {
    return getCustomModelCollectionRef(this.firestore);
  }

  private getCustomModelDocRef(modelId: string) {
    return getCustomModelDocRef({
      firestore: this.firestore,
      modelId,
    });
  }

  private getCustomModelDatasetRef(modelId: string) {
    return getCustomModelDatasetCollectionRef({
      firestore: this.firestore,
      modelId,
    });
  }

  private getCustomModelTrainingColectionRef(modelId: string) {
    return getCustomModelTrainingCollectionRef({
      firestore: this.firestore,
      modelId,
    });
  }

  private getCustomModelTrainingDocRef({
    modelId,
    trainingId,
  }: {
    modelId: string;
    trainingId: string;
  }) {
    return getCustomModelTrainingDocRef({
      firestore: this.firestore,
      modelId,
      trainingId,
    });
  }

  private getCustomModelPredictionCollectionRef() {
    return getCustomModelPredictionCollectionRef({
      firestore: this.firestore,
    });
  }

  private getCustomModelPredictionDocRef({ predictionId }: { predictionId: string }) {
    return getCustomModelPredictionDocRef({
      firestore: this.firestore,
      predictionId,
    });
  }

  private getCustomModelDatasetItemRef(modelId: string, dataId: string) {
    return getCustomModelDatasetItemDocRef({
      firestore: this.firestore,
      dataId,
      modelId,
    });
  }

  private static getCustomModelDatasetFromDocs<T>(
    docs: QueryDocumentSnapshot<T>[],
  ): CustomModelDataset {
    const output: CustomModelDataset = {};
    docs.forEach((doc) => {
      const item = doc.data();
      if (isCustomModelDatasetItem(item)) {
        output[doc.id] = item;
      }
    });
    return output;
  }

  getCustomModelDataset(modelId: string): Promise<CustomModelDataset> {
    return getDocs(this.getCustomModelDatasetRef(modelId))
      .then((snap) => snap.docs)
      .then(CustomModelManager.getCustomModelDatasetFromDocs);
  }

  onCustomModelDatasetUpdate(modelId: string, callback: (dataset?: CustomModelDataset) => void) {
    return onSnapshot(this.getCustomModelDatasetRef(modelId), (snapshot) => {
      callback(CustomModelManager.getCustomModelDatasetFromDocs(snapshot.docs));
    });
  }

  async setCustomModelDataItem({
    modelId,
    dataId,
    data,
  }: {
    modelId: string;
    dataId: string;
    data: Partial<CustomModelDatasetItem>;
  }) {
    data = {
      ...data,
      timeCreated: serverTimestamp() as Timestamp,
      timeModified: serverTimestamp() as Timestamp,
    };

    if (!data?.id || !data?.storagePath) {
      debugError(`Cannot set model ${modelId} data ${dataId}: `, data);
      return;
    }

    return setDoc(
      this.getCustomModelDatasetItemRef(modelId, dataId),
      removeUndefinedFromObject(data),
    );
  }

  updateCustomModelDataItem({
    modelId,
    dataId,
    data,
  }: {
    modelId: string;
    dataId: string;
    data: Partial<CustomModelDatasetItem>;
  }) {
    data = {
      ...data,
      timeModified: serverTimestamp() as Timestamp,
    };

    return updateDoc(
      this.getCustomModelDatasetItemRef(modelId, dataId),
      removeUndefinedFromObject(data),
    );
  }

  async deleteCustomModelDataItem({ modelId, dataId }: { modelId: string; dataId: string }) {
    debugLog(`Delete model ${modelId} data ${dataId}`);
    return await deleteDoc(this.getCustomModelDatasetItemRef(modelId, dataId));
  }

  onCustomModelDatasetItemUpdate(
    modelId: string,
    dataId: string,
    callback: (dataItem?: CustomModelDatasetItem) => void,
  ) {
    return onSnapshot(this.getCustomModelDatasetItemRef(modelId, dataId), (snapshot) => {
      const data = snapshot.data();
      if (isCustomModelDatasetItem(data)) {
        callback(data);
      } else {
        callback(undefined);
      }
    });
  }

  private async generateAssetUploadUrl({
    assetType,
    contentType,
    tags,
    extensionHeaders,
  }: GenerateUserAssetUploadUrlArgs): Promise<GenerateUserAssetUploadUrlResponse> {
    const currentTeamId = getCurrentTeamId();
    const response = await this.generateUserAssetUploadUrlColabJuly24({
      assetType,
      contentType,
      publicTeamId: currentTeamId,
      tags,
      extensionHeaders,
    });

    const { assetId, signedUrl, extensionHeaders: responseExtensionHeaders } = response.data ?? {};

    return {
      assetId,
      signedUrl,
      extensionHeaders: responseExtensionHeaders,
    };
  }

  async uploadAssetToStorage({
    data,
    assetType,
    tags,
    extensionHeaders,
    publicTeamId,
  }: UploadCustomModelAssetToStorageArgs): Promise<UploadCustomModelAssetToStorageResponse> {
    try {
      const contentType = data.type;

      const {
        assetId,
        signedUrl,
        extensionHeaders: responseExtensionHeaders,
      } = await this.generateAssetUploadUrl({
        assetType,
        contentType,
        tags,
        extensionHeaders,
        publicTeamId,
      });

      if (!signedUrl || !assetId) {
        return;
      }

      const storagePath = getStoragePathFromSignedUrl(signedUrl);

      if (!storagePath) {
        return undefined;
      }

      const headers = Object.fromEntries(
        Object.entries(responseExtensionHeaders).filter(([, value]) => value != null),
      ) as Record<string, string>;
      await uploadDataWithMetadata(signedUrl, data, contentType, headers);

      return storagePath;
    } catch (error) {
      return undefined;
    }
  }

  private async generateCustomModelDatasetItemAssetUploadUrl(
    args: Omit<GenerateUserAssetUploadUrlArgs, "assetType"> & {
      contentType: string;
    },
  ) {
    return await this.generateAssetUploadUrl({
      ...args,
      assetType: UserAssetType.CustomModelDatasetItem,
    });
  }

  private get userQuotas() {
    return getUserQuotasFromEditorContext(editorContextStore.getState());
  }

  async uploadCustomModelDataItemToStorage({
    data,
    modelId,
    publicTeamId,
  }: UploadCustomModelDataItemToStorageArgs): Promise<UploadCustomModelDataItemToStorageResponse> {
    try {
      const { eventEmitter } = editorContextStore.getState();
      const currentTeamId = getCurrentTeamId();
      const userQuotas = this.userQuotas;

      if (!canUserUploadAsset(userQuotas)) {
        eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
          "ui:display-message-dialog",
          "quota-subscribe",
          {
            title: "No custom model storage space left.",
            header: "You have used all custom model storage space.",
          },
        );
        return;
      }

      const contentType = data.type;

      const {
        assetId,
        signedUrl,
        extensionHeaders = {},
      } = await this.generateCustomModelDatasetItemAssetUploadUrl({
        contentType,
        publicTeamId: publicTeamId || currentTeamId,
      });

      if (!signedUrl || !assetId) {
        debugError(`Cannot generate upload url for asset ${assetId} of type ${data.type}`);
        return undefined;
      }

      const storagePath = getStoragePathFromSignedUrl(signedUrl);

      debugLog(`Upload data to storage path ${storagePath} with signed url ${signedUrl}`);

      if (!storagePath) {
        return undefined;
      }

      const headers = Object.fromEntries(
        Object.entries(extensionHeaders).filter(([, value]) => value != null),
      ) as Record<string, string>;

      await uploadDataWithMetadata(signedUrl, data, contentType, headers);

      return storagePath;
    } catch (error) {
      debugError(`Cannot upload model ${modelId} data item to storage`);
    }
    return undefined;
  }

  private getPublicCustomModelsQuery() {
    return query(
      this.getCustomModelCollectionRef(),
      where("visibility", "==", DocVisibility.Public),
    );
  }

  private async getPublicCustomModelsInternal() {
    try {
      const snapshot = await getDocs(this.getPublicCustomModelsQuery());

      const models = snapshot.docs
        .map((doc) => {
          if (!doc.exists()) {
            return undefined;
          }

          const customModelInfo = doc.data();

          if (!isCustomModelInfo(customModelInfo)) {
            return undefined;
          }

          return customModelInfo;
        })
        .filter(Boolean) as CustomModelInfo[];

      return models;
    } catch (error) {
      debugError("Cannot get public custom models: ", error);
    }
    return [];
  }

  async getPublicCustomModels() {
    if (this.cachedPublicCustomModels.length > 0) {
      return this.cachedPublicCustomModels;
    }

    this.cachedPublicCustomModels = await this.getPublicCustomModelsInternal();

    return this.cachedPublicCustomModels;
  }

  private getUserCustomModelsQuery({
    publicUserId,
    publicTeamId,
  }: {
    publicUserId: PublicUserId;
    publicTeamId: PublicTeamId;
  }) {
    const queryConstraints: QueryConstraint[] = [];

    if (isPublicTeamId(publicTeamId)) {
      queryConstraints.push(where("publicTeamId", "==", publicTeamId));
    } else {
      queryConstraints.push(where(`roles.${publicUserId}`, "in", Object.values(AppRoleType)));
    }

    debugLog("getUserCustomModelsQuery constraints: ", queryConstraints);

    return query(this.getCustomModelCollectionRef(), ...queryConstraints);
  }

  onUserCustomModelsUpdate({ publicUserId, publicTeamId, callback }: OnUserCustomModelsUpdateArgs) {
    return onSnapshot(
      this.getUserCustomModelsQuery({ publicUserId, publicTeamId }),
      (customModelsSnapshot) => {
        const newModels: Record<string, CustomModelInfo> = {};
        customModelsSnapshot.docs.forEach((customModelDocRef) => {
          const project = customModelDocRef.data();
          if (isCustomModelInfo(project) && project.isDeleted !== true) {
            newModels[customModelDocRef.id] = project;
          }
        });
        callback(newModels);
      },
    );
  }

  async createCustomModel(args: CreateCustomModelArgs): Promise<HandleCreateCustomModelResponse> {
    try {
      debugLog("Create custom model args:\n", args);

      const { eventEmitter } = editorContextStore.getState();
      const userIsTeamMember = isRoleTeamMember(editorContextStore.getState());

      const userQuotas = this.userQuotas;

      if (
        !canUserCreateCustomModel({
          userQuotas,
        })
      ) {
        if (userIsTeamMember) {
          eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
            "ui:display-message-dialog",
            "quota-subscribe",
            {
              title: "Contact team owner to increase quota.",
              header: "You have used all custom model credits.",
            },
          );

          return {
            ok: false,
            message: "No custom model quota left.",
          };
        } else {
          eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
            "ui:display-message-dialog",
            "quota-subscribe",
            {
              title: "No custom model credits left.",
              header: "You have used all custom model credits.",
            },
          );

          return {
            ok: false,
            message: "No custom model quota left.",
          };
        }
      }

      const currentTeamId = getCurrentTeamId();
      const response = await this.customModelActionEntrypoint({
        ...args,
        type: CustomModelAction.CreateNewModel,
        publicTeamId: currentTeamId,
      });

      const data = response.data;

      return data as HandleCreateCustomModelResponse;
    } catch (error) {
      debugError("Cannot create new custom model: ", error);
    }

    return {
      ok: false,
      message: "Unknown error when creating custom model.",
    };
  }

  async deleteCustomModel({ modelId }: DeleteCustomModelArgs): Promise<DeleteCustomModelResponse> {
    try {
      const newCustomModelDoc: Partial<CustomModelInfo> = {
        isDeleted: true,
      };

      await updateDoc(
        this.getCustomModelDocRef(modelId),
        removeUndefinedFromObject(newCustomModelDoc),
      );

      return {
        ok: true,
        message: "OK.",
      };
    } catch (error) {
      debugError(`Error when deleting custom model ${modelId}: `, error);
    }
    return {
      ok: false,
      message: "Cannot delete model.",
    };
  }

  async startCustomModelTraining(
    args: StartCustomModelTrainingArgs,
  ): Promise<HandleCustomModelTrainingStartResponse> {
    try {
      debugLog("Start custom model args:\n", args);

      const { eventEmitter } = editorContextStore.getState();
      const isTeamMember = isRoleTeamMember(editorContextStore.getState());
      const userQuotas = this.userQuotas;

      debugLog("User quotas:\n", userQuotas);

      const canStartResponse = canUserStartTraining({
        userQuotas,
        input: args.trainingInput,
        isTeamMember,
      });

      if (!canStartResponse.ok) {
        const { message, title, header } =
          canStartResponse as CanUserRunCustomModelPostProcessingResponseNotAllowed;

        eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
          "ui:display-message-dialog",
          "quota-subscribe",
          {
            title,
            header,
          },
        );

        return {
          ok: false,
          message,
        };
      }
      const currentTeamId = getCurrentTeamId();
      const response = await this.customModelActionEntrypoint({
        ...args,
        type: CustomModelAction.StartTraining,
        publicTeamId: currentTeamId,
      });

      const data = response.data;

      return data as HandleCustomModelTrainingStartResponse;
    } catch (error) {
      debugError("Cannot start custom model training");
    }

    return {
      ok: false,
      message: "Unknown error when starting custom model training.",
    };
  }

  async stopCustomModelTraining(args: StopCustomModelTrainingArgs) {
    try {
      debugLog("Stop custom model args:\n", args);

      const response = await this.customModelActionEntrypoint({
        ...args,
        type: CustomModelAction.StopTraining,
      });

      const data = response.data;

      return data as HandleCustomModelTrainingStopResponse;
    } catch (error) {
      debugError("Cannot stop new custom model: ", error);
    }
    return {
      ok: false,
      message: "Unknown error when stopping the custom model.",
    };
  }

  private static correctCustomModelPredictionInputFalScaleConfigs(
    input: Partial<CustomModelPredictionInputFal>,
  ): Partial<CustomModelPredictionInputFal> {
    const scaleConfigs = correctCustomModelScaleConfigs(input.scaleConfigs ?? {});

    return {
      ...input,
      scaleConfigs,
    };
  }

  private static correctCustomModelPredictionInputSelfHostScaleConfigs(
    input: Partial<CustomModelPredictionInputSelfHost>,
  ): Partial<CustomModelPredictionInputSelfHost> {
    const scaleConfigs = correctCustomModelScaleConfigs(input.scale_configs ?? {});

    return {
      ...input,
      scale_configs: scaleConfigs,
    };
  }

  private static correctCustomModelPredictionInputScaleConfigs(
    input: Partial<CustomModelPredictionInput>,
  ): Partial<CustomModelPredictionInput> {
    try {
      if (input.backendType === CustomModelPredictionInputBackendType.Fal) {
        return CustomModelManager.correctCustomModelPredictionInputFalScaleConfigs(
          input as CustomModelPredictionInputFal,
        );
      }

      if (input.backendType === CustomModelPredictionInputBackendType.SelfHost) {
        return CustomModelManager.correctCustomModelPredictionInputSelfHostScaleConfigs(
          input as CustomModelPredictionInputSelfHost,
        );
      }

      return input;
    } catch (error) {
      debugError("Error correcting custom model prediction input scale configs: ", error);
      return input;
    }
  }

  async startCustomModelPrediction(
    args: StartCustomModelPredictionArgs,
  ): Promise<HandleStartCustomModelPredictionResponse> {
    try {
      debugLog("Start custom model prediction args:\n", args);

      const { eventEmitter } = editorContextStore.getState();
      const currentTeamId = getCurrentTeamId();

      const userQuotas = this.userQuotas;

      if (!canUserStartPrediction({ userQuotas })) {
        eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
          "ui:display-message-dialog",
          "quota-subscribe",
          {
            title: "No custom model generate credits left.",
            header: "You have used all custom model credits.",
          },
        );

        return {
          ok: false,
          message: "No custom model generate quota left.",
        };
      }
      const response = await this.customModelActionEntrypoint({
        ...args,
        input: CustomModelManager.correctCustomModelPredictionInputScaleConfigs(args.input),
        type: CustomModelAction.StartPrediction,
        publicTeamId: currentTeamId,
      });

      const data = response.data;

      return data as HandleStartCustomModelPredictionResponse;
    } catch (error) {
      debugError("Cannot start custom model training: ", error);
    }
    return {
      ok: false,
      message: "Unknown error when starting the custom model.",
    };
  }

  async stopCustomModelPrediction(
    args: StopCustomModelPredictionArgs,
  ): Promise<HandleStopCustomModelPredictionResponse> {
    try {
      const response = await this.customModelActionEntrypoint({
        ...args,
        type: CustomModelAction.StopPrediction,
      });

      const data = response.data;

      return data as HandleStopCustomModelPredictionResponse;
    } catch (error) {
      debugError("Cannot stop custom model training: ", error);
    }
    return {
      ok: false,
      message: "Unknown error when stopping the custom model.",
    };
  }

  async deleteCustomModelPrediction({
    predictionId,
  }: DeleteCustomModelPredictionArgs): Promise<DeleteCustomModelPredictionResponse> {
    try {
      const newCustomModelPredictionDoc: Partial<CustomModelPredictionItem> = {
        isDeleted: true,
      };

      await updateDoc(
        this.getCustomModelPredictionDocRef({
          predictionId,
        }),
        removeUndefinedFromObject(newCustomModelPredictionDoc),
      );

      return {
        ok: true,
        message: "OK.",
      };
    } catch (error) {
      debugError("Cannot delete custom model prediction: ", error);
    }

    return {
      ok: false,
      message: "Cannot delete custom model prediction.",
    };
  }

  onCustomModelPredictionUpdate({ predictionId, callback }: OnCustomModelPredictionUpdateArgs) {
    return onSnapshot(
      this.getCustomModelPredictionDocRef({
        predictionId,
      }),
      (snapshot) => {
        const data = snapshot.data();

        if (!isCustomModelPredictionItem(data)) {
          return;
        }

        callback(data);
      },
    );
  }

  async getCustomModelPredictionItem(
    predictionId: string,
  ): Promise<CustomModelPredictionItem | undefined> {
    try {
      // Get a reference to the prediction document in Firestore
      const customModelPredictionRef = this.getCustomModelPredictionDocRef({
        predictionId,
      });

      // Fetch the document snapshot
      const snapshot = await getDoc(customModelPredictionRef);

      // Extract the data from the snapshot
      const data = snapshot.data();

      // Validate the data using the type guard
      if (isCustomModelPredictionItem(data)) {
        return data;
      }

      // If data is not a valid CustomModelPredictionItem, return undefined
      return undefined;
    } catch (error) {
      // Log the error for debugging purposes
      debugError(`Error fetching prediction item ${predictionId}:`, error);
      return undefined;
    }
  }

  private getUserCustomModelPredictionsQuery({
    publicUserId,
    publicTeamId,
    modelId,
  }: {
    publicUserId: PublicUserId;
    modelId?: string;
    publicTeamId: PublicTeamId;
  }) {
    const queryConstraints: QueryConstraint[] = [];

    if (publicTeamId && isPublicTeamId(publicTeamId)) {
      queryConstraints.push(where("publicTeamId", "==", publicTeamId));
    } else {
      queryConstraints.push(where(`roles.${publicUserId}`, "in", Object.values(AppRoleType)));
    }

    if (modelId) {
      queryConstraints.push(where(`usedModels.${modelId}`, "==", true));
    }

    // debugLog(`Custom model ${modelId} predictions query constraints: `, queryConstraints);
    return query(this.getCustomModelPredictionCollectionRef(), ...queryConstraints);
  }

  private getPublicCustomModelPredictionsQuery({ modelId }: { modelId?: string }) {
    const queryConstraints: QueryConstraint[] = [where("visibility", "==", DocVisibility.Public)];

    if (modelId) {
      queryConstraints.push(where(`usedModels.${modelId}`, "==", true));
    }

    // debugLog(`Custom model ${modelId} predictions query constraints: `, queryConstraints);

    return query(this.getCustomModelPredictionCollectionRef(), ...queryConstraints);
  }

  private async getPublicCustomModelPredictionsInternal({
    modelId,
  }: GetPublicCustomModelPredictionsArgs): Promise<GetPublicCustomModelPredictionsResponse> {
    const querySnapshot = await getDocs(
      this.getPublicCustomModelPredictionsQuery({
        modelId,
      }),
    );

    const outputPredictions: Record<string, CustomModelPredictionItem> = {};

    querySnapshot.docs.forEach((predictionDocRef) => {
      const predictionDoc = predictionDocRef.data();

      if (!isCustomModelPredictionItem(predictionDoc)) {
        return;
      }

      outputPredictions[predictionDoc.id] = predictionDoc;
    });

    // debugLog(`Get public custom model ${modelId} predictions:\n`, outputPredictions);

    return outputPredictions;
  }

  async getPublicCustomModelPredictions(
    args: GetPublicCustomModelPredictionsArgs,
  ): Promise<GetPublicCustomModelPredictionsResponse> {
    return this.getPublicCustomModelPredictionsInternal(args);
  }

  onCustomModelPredictionsUpdate({
    publicUserId,
    publicTeamId,
    modelId,
    callback,
  }: OnCustomModelPredictionsUpdateArgs) {
    return onSnapshot(
      this.getUserCustomModelPredictionsQuery({
        publicUserId,
        publicTeamId,
        modelId,
      }),
      (customModelsSnapshot) => {
        // debugLog(`Custom model ${modelId} predictions:\n`, customModelsSnapshot.docs.length);

        const newPredictions: Record<string, CustomModelPredictionItem> = {};
        customModelsSnapshot.docs.forEach((predictionDocRef) => {
          const predictionDoc = predictionDocRef.data();

          if (!isCustomModelPredictionItem(predictionDoc)) {
            return;
          }

          newPredictions[predictionDoc.id] = predictionDoc;
        });
        callback(newPredictions);
      },
    );
  }

  async getCustomModelTrainings({
    modelId,
  }: GetCustomModelTrainingsArgs): Promise<GetCustomModelTrainingsResponse> {
    const snapshot = await getDocs(this.getCustomModelTrainingColectionRef(modelId));

    return snapshot.docs
      .map((doc) => {
        const data = doc.data();

        if (isCustomModelTrainingItem(data)) {
          return data;
        }

        return undefined;
      })
      .filter(Boolean) as GetCustomModelTrainingsResponse;
  }

  async getCustomModelTraining({
    modelId,
    trainingId,
  }: GetCustomModelTrainingArgs): Promise<CustomModelTrainingItem | undefined> {
    const snapshot = await getDoc(
      this.getCustomModelTrainingDocRef({
        modelId,
        trainingId,
      }),
    );

    const data = snapshot.data();

    if (isCustomModelTrainingItem(data)) {
      return data;
    }

    return undefined;
  }

  onCustomModelTrainingUpdate({ modelId, trainingId, callback }: OnCustomModelTrainingUpdateArgs) {
    return onSnapshot(
      this.getCustomModelTrainingDocRef({
        modelId,
        trainingId,
      }),
      (snapshot) => {
        const data = snapshot.data();

        if (isCustomModelTrainingItem(data)) {
          return callback(data);
        }
      },
    );
  }

  onCustomModelTrainingCollectionUpdate({
    modelId,
    callback,
  }: OnCustomModelTrainingCollectionUpdateArgs) {
    return onSnapshot(this.getCustomModelTrainingColectionRef(modelId), (snapshot) => {
      return callback(
        snapshot.docs
          .map((doc) => doc.exists() && doc.data())
          .filter((data) => data && isCustomModelTrainingItem(data)) as CustomModelTrainingItem[],
      );
    });
  }

  async getCustomModelInfo(modelId: string): Promise<CustomModelInfo | undefined> {
    try {
      const snapshot = await getDoc(this.getCustomModelDocRef(modelId));

      const data = snapshot.data();
      if (!isCustomModelInfo(data)) {
        return undefined;
      }

      return data;
    } catch (error) {
      debugError("Error fetching custom model info:", error);
      return undefined;
    }
  }

  async updateCustomModelInfo({ modelId, modelInfo }: UpdateCustomModelInfoArgs) {
    try {
      modelInfo = removeUndefinedFromObject(modelInfo);

      if (Object.keys(modelInfo).length <= 0) {
        debugLog(`Model ${modelId} update info is empty.`);

        return;
      }

      await updateDoc(this.getCustomModelDocRef(modelId), removeUndefinedFromObject(modelInfo));
    } catch (error) {
      debugError(`Cannot update custom model ${modelId}`, error);
    }
  }

  static CustomModelPostProcessAuthToken = import.meta.env.VITE_CUSTOM_MODEL_POST_PROCESS_API_KEY;

  static CustomModelPostProcessApiUrl = import.meta.env.VITE_CUSTOM_MODEL_POST_PROCESS_API_URL;

  private callCustomModelPostProcessApi = debounce(
    async ({
      userId,
      ...args
    }: CustomModelPostProcessInput & {
      userId: string;
    }) => {
      try {
        const editorState = editorContextStore.getState();
        const { eventEmitter, currentTeamId } = editorState;
        const userIsTeamMember = isRoleTeamMember(editorState);

        const userQuotas = this.userQuotas;

        const canStartResponse = canUserRunCustomModelPostProcessing({
          userQuotas,
          userIsTeamMember,
        });

        if (!canStartResponse.ok) {
          const { message, title, header } =
            canStartResponse as CanUserRunCustomModelPostProcessingResponseNotAllowed;

          eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
            "ui:display-message-dialog",
            "quota-subscribe",
            {
              title,
              header,
            },
          );

          return {
            ok: false,
            message,
          };
        }

        const response = await fetch(CustomModelManager.CustomModelPostProcessApiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${CustomModelManager.CustomModelPostProcessAuthToken}`,
            UserId: userId,
          },
          body: JSON.stringify({ ...args, publicTeamId: currentTeamId }), // publicteamid makes sure the quota is charged to the correct team
        });

        const data = (await response.json()) as CustomModelPostProcessResponse;

        return data;
      } catch (error) {
        debugError("Error when post-processing image: ", error);

        return {
          ok: false,
          message: "Unknown error when post-processing image.",
        };
      }
    },
    1000,
  );

  async getMaskFromPrompt({
    userId,
    ...args
  }: GetMaskFromPromptInput & {
    userId: string;
  }): Promise<GetMaskFromPromptResponse> {
    try {
      return (await this.callCustomModelPostProcessApi({
        ...args,
        userId,
      })) as GetMaskFromPromptResponse;
    } catch (error) {
      debugError("Error when getting mask from prompt: ", error);

      return {
        ok: false,
        message: "Unknown error when getting mask from prompt.",
      };
    }
  }

  async swapFace({
    userId,
    ...args
  }: SwapFaceInput & {
    userId: string;
  }): Promise<SwapFaceResponse> {
    try {
      return (await this.callCustomModelPostProcessApi({
        ...args,
        userId,
      })) as SwapFaceResponse;
    } catch (error) {
      debugError("Error swapping face: ", error);

      return {
        ok: false,
        message: "Unknown error when swapping face.",
      };
    }
  }

  async generateVariationsInContext({
    userId,
    ...args
  }: InContextVariationsInput & {
    userId: string;
  }): Promise<InContextVariationsResponse> {
    try {
      return (await this.callCustomModelPostProcessApi({
        ...args,
        userId,
      })) as InContextVariationsResponse;
    } catch (error) {
      debugError("Error generating variations in-context: ", error);
      return {
        ok: false,
        message: "Unknown error when generating variations.",
      };
    }
  }

  async fixProductDetails({
    userId,
    ...args
  }: FixProductDetailsInput & {
    userId: string;
  }): Promise<FixProductDetailsResponse> {
    try {
      return (await this.callCustomModelPostProcessApi({
        ...args,
        userId,
      })) as FixProductDetailsResponse;
    } catch (error) {
      debugError("Error when fixing product details: ", error);

      return {
        ok: false,
        message: "Unknown error when getting fixing product details.",
      };
    }
  }

  async upscaleCreative({
    userId,
    ...args
  }: ClarityUpscaleInput & {
    userId: string;
  }): Promise<ClarityUpscaleResponse> {
    try {
      const response = await this.callCustomModelPostProcessApi({
        ...args,
        userId,
      });

      return response as ClarityUpscaleResponse;
    } catch (error) {
      debugError("Error when running upscale creative: ", error);

      return {
        ok: false,
        message: "Unknown error when running upscale creative.",
      };
    }
  }

  async upscaleFace({
    userId,
    ...args
  }: FaceUpscalerInput & { userId: string }): Promise<FaceUpscalerResponse> {
    try {
      const response = await this.callCustomModelPostProcessApi({
        ...args,
        userId,
      });

      return response as FaceUpscalerResponse;
    } catch (error) {
      debugError("Error when running upscale face: ", error);

      return {
        ok: false,
        message: "Unknown error when running face upscale.",
      };
    }
  }

  async multistepGeneration({
    userId,
    ...args
  }: MultiStageGenerationInput & {
    userId: string;
  }): Promise<MultiStageGenerationResponse> {
    try {
      const response = await this.callCustomModelPostProcessApi({
        ...args,
        userId,
      });

      return response as MultiStageGenerationResponse;
    } catch (error) {
      debugError("Error calling custom model mult-step generation response: ", error);
      return {
        ok: false,
        message: "Unknown error when running multi-step generation.",
      };
    }
  }

  // async customModelFixDetails({
  //   userId,
  //   ...args
  // }: CustomModelFixDetailsArgs & {
  //   userId: string;
  // }): Promise<CustomModelFixDetailsResponse> {
  //   try {
  //     const response = await fetch(CustomModelManager.CustomModelPostProcessApiUrl, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${CustomModelManager.CustomModelPostProcessAuthToken}`,
  //         UserId: userId,
  //       },
  //       body: JSON.stringify(args),
  //     });

  //     if (!response.ok) {
  //       return {
  //         ok: false,
  //         message: await response.text(),
  //       };
  //     }

  //     const data = await response.json();

  //     if (isCustomModelFixDetailsResponse(data)) {
  //       return data;
  //     }

  //     return {
  //       ok: false,
  //       message: "Invalid response data.",
  //     };
  //   } catch (error) {
  //     debugError("Error running custom model fix details: ", error);

  //     return {
  //       ok: false,
  //       message: "Unknown error when running fixing details.",
  //     };
  //   }
  // }

  async customModelFixDetailsCorrespondence(
    input: FixCustomModelDetailsCorrespondenceInput,
  ): Promise<FixCustomModelDetailsCorrespondenceOutput> {
    try {
      const { teamQuotas, eventEmitter } = editorContextStore.getState();

      const userQuotas = this.userQuotas;

      const canStartResponse = canUserRunCustomModelPostProcessing({
        userQuotas: teamQuotas || userQuotas,
      });

      if (!canStartResponse.ok) {
        const { message, title, header } =
          canStartResponse as CanUserRunCustomModelPostProcessingResponseNotAllowed;

        eventEmitter.emit<UiDisplayMessageDialogEventHandler>(
          "ui:display-message-dialog",
          "quota-subscribe",
          {
            title,
            header,
          },
        );

        return {
          ok: false,
          message,
        };
      }

      if (!input.sourceImage) {
        return {
          ok: false,
          message: "Please provide a valid reference image.",
        };
      }

      if (!input.targetImage) {
        return {
          ok: false,
          message: "Please provide a valid target image.",
        };
      }

      const response = await this.fixCustomModelDetailsCorrespondence(input);

      const responseData = response.data;

      if (isFixCustomModelDetailsCorrespondenceOutput(responseData)) {
        return responseData;
      }

      debugError("Invalid response from the fix details correspondence server: ", responseData);

      return {
        ok: false,
        message: "Internal server error.",
      };
    } catch (error) {
      debugError("Error calling custom model fix details correspondence: ", error);

      return {
        ok: false,
        message: "Internal server error.",
      };
    }
  }

  private async generateCustomModelVirtualTryOnInputAssetUploadUrl({
    contentType,
    publicTeamId,
  }: {
    contentType: string;
    publicTeamId: PublicTeamId;
  }) {
    return await this.generateAssetUploadUrl({
      contentType,
      assetType: UserAssetType.TryOnClothImage,
      publicTeamId,
    });
  }

  async uploadCustomModelVirtualTryOnInputToStorage({
    data,
    publicTeamId,
  }: UploadCustomModelVirtualTryOnInputToStorageArgs): Promise<UploadCustomModelVirtualTryOnInputToStorageResponse> {
    try {
      const contentType = data.type;

      const {
        assetId,
        signedUrl,
        extensionHeaders = {},
      } = await this.generateCustomModelVirtualTryOnInputAssetUploadUrl({
        contentType,
        publicTeamId,
      });

      if (!signedUrl || !assetId) {
        return;
      }

      const storagePath = getStoragePathFromSignedUrl(signedUrl);

      if (!storagePath) {
        return undefined;
      }

      const headers = Object.fromEntries(
        Object.entries(extensionHeaders).filter(([, value]) => value != null),
      ) as Record<string, string>;

      await uploadDataWithMetadata(signedUrl, data, contentType, headers);

      return storagePath;
    } catch (error) {
      debugError("Cannot upload data to storage");
      return undefined;
    }
  }

  async callCustomModelVirtualTryOn({
    userId,
    ...args
  }: TryOnInput & {
    userId: string;
  }): Promise<TryOnResponse> {
    try {
      return (await this.callCustomModelPostProcessApi({
        ...args,
        userId,
      })) as TryOnResponse;
    } catch (error) {
      debugError("Error when getting mask from prompt: ", error);

      return {
        ok: false,
        message: "Unknown error when getting mask from prompt.",
      };
    }
  }

  async updateCustomModelDatasetItemThumbnail({
    teamId,
    modelId,
    assetStoragePath,
  }: UpdateCustomModelDatasetItemThumbnailArgs): Promise<UpdateCustomModelDatasetItemThumbnailResponse> {
    const response = await this.updateCustomModelDatasetItemThumbnailColabJuly24({
      teamId,
      modelId,
      assetStoragePath,
    });

    return response.data;
  }

  autoCorrectCustomModelCaption: (
    args: AutoCorrectCustomModelCaptionArgs,
  ) => Promise<AutoCorrectCustomModelCaptionResponse> = debounce(
    async (args: AutoCorrectCustomModelCaptionArgs) => {
      try {
        const response = await this.autoCorrectCustomModelCaptionColabJuly24(args);
        return response.data;
      } catch (error) {
        debugError("Error auto-correcting custom model caption: ", error);
        return {
          ok: false,
        };
      }
    },
    1000,
  );

  async appendTextToPrompt({
    textToAdd,
    prompt,
    languageModel,
    triggerWordsToMaintain,
  }: AppendTextToPromptArgs): Promise<AppendTextToPromptResponse> {
    try {
      const llmPrompt = appendPromptInstructions({
        textToAdd,
        prompt,
        triggerWordsToMaintain,
      });
      debugLog("Calling language model: ", languageModel);
      const responseText = await this.backend?.callAnyLanguageModel({
        input: {
          model: languageModel,
          prompt: llmPrompt,
        },
      });
      debugLog("Response text from append text to prompt: ", responseText);

      if (!responseText) {
        return {
          ok: false,
          data: { modifiedPrompt: "" },
        };
      }
      const cleanedText = extractTripleTickContent(responseText) || responseText;
      const modifiedPrompt = extractModifiedPrompt(cleanedText);

      if (modifiedPrompt) {
        return {
          ok: true,
          data: {
            modifiedPrompt,
          },
        };
      }

      return null;
    } catch (error) {
      debugError("Error appending text to prompt: ", error);
      return null;
    }
  }

  async removeTextFromPrompt({
    textToRemove,
    prompt,
    triggerWordsToRemove,
    triggerWordsToMaintain,
    languageModel,
  }: RemoveTextFromPromptArgs): Promise<RemoveTextFromPromptResponse> {
    try {
      const llmPrompt = removePromptInstructions({
        textToRemove,
        prompt,
        triggerWordsToRemove,
        triggerWordsToMaintain,
      });
      const responseText = await this.backend?.callAnyLanguageModel({
        input: {
          model: languageModel,
          prompt: llmPrompt,
        },
      });
      debugLog("Response text from remove text from prompt: ", responseText);
      if (!responseText) {
        return null;
      }
      const cleanedText = extractTripleTickContent(responseText) || responseText;
      const modifiedPrompt = extractModifiedPrompt(cleanedText);

      if (modifiedPrompt) {
        return {
          ok: true,
          data: {
            modifiedPrompt,
          },
        };
      }

      return null;
    } catch (error) {
      debugError("Error removing text from prompt: ", error);
      return null;
    }
  }
}

import { editorContextStore } from "@/contexts/editor-context";
import { Assets } from "@/core/controllers/assets";
import { isDataURL, isValidFirebaseStoragePath, isValidHttpsUrl } from "@/core/utils/string-utils";
import { preprocessImageUrl } from "@/core/utils/url-utils";
import { BaseImage } from "@ando-editor/ui-components";
import React from "react";

export const ImageComponentPrimitive = React.forwardRef(
  (
    {
      style = {},
      onLoad,
      onError,
      alt,
      src,
      isImageLoaded,
      setImageLoaded,
      ...props
    }: React.ImgHTMLAttributes<HTMLImageElement> & {
      isImageLoaded: boolean;
      setImageLoaded: (value: boolean) => void;
    },
    forwardedRef?: React.ForwardedRef<HTMLImageElement>,
  ) => {
    const storageManager = editorContextStore((state) => state.storageManager);
    const [imageUrl, setImageUrl] = React.useState<string | undefined>(undefined);

    const handleImageLoadError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      setImageLoaded(false);

      if (src === imageUrl) {
        return;
      } else {
        setImageUrl(src);
      }

      onError?.(e);
    };

    React.useEffect(() => {
      if (!src) {
        return;
      }

      if (isDataURL(src) || isValidHttpsUrl(src)) {
        setImageUrl(preprocessImageUrl(src));
      } else if (storageManager && isValidFirebaseStoragePath(src)) {
        Assets.loadAssetFromPath({
          path: src,
          storageManager,
        }).then((imageUrl) => {
          if (!imageUrl) {
            return;
          }

          setImageUrl(imageUrl);
        });
      }
    }, [src, storageManager, setImageLoaded]);

    return (
      <BaseImage
        {...props}
        ref={forwardedRef}
        alt={alt}
        src={imageUrl}
        style={{
          ...style,
        }}
        onLoad={(e) => {
          setImageLoaded(true);
          onLoad?.(e);
        }}
        onError={handleImageLoadError}
      />
    );
  },
);

export const ImageComponent = React.forwardRef(
  (
    props: React.ImgHTMLAttributes<HTMLImageElement>,
    forwardedRef: React.ForwardedRef<HTMLImageElement>,
  ) => {
    const [isImageLoaded, setImageLoaded] = React.useState(false);
    return (
      <ImageComponentPrimitive
        {...props}
        ref={forwardedRef}
        isImageLoaded={isImageLoaded}
        setImageLoaded={setImageLoaded}
      />
    );
  },
);

import { Backend } from "@/backend/base";
import { parseDirtyJSON } from "@/backend/firebase/asset-upload-utils";
import { FlairStorageManager } from "@/backend/firebase/storage/storage-manager";
import {
  AppUserQuotas,
  AppUserSubscriptionTierV2,
  CustomModelDatasetItem,
  CustomModelInfo,
  CustomModelPlaygroundPromptEditorState,
  CustomModelTrainingInput,
  CustomModelTrainingItem,
  CustomModelType,
  getDisplayNameFromId,
  isFastTrainingBackendType,
} from "@/core/common/types";
import { Assets } from "@/core/controllers/assets";
import { debugError, debugLog } from "@/core/utils/print-utilts";
import { generateUUID } from "@/core/utils/uuid-utils";
import { extractTripleTickContent } from "./string-utils";

export type CanUserRunCustomModelPostProcessingResponseNotAllowed = {
  ok: false;
  message: string;
  title: string;
  header: string;
};

export type CanUserRunCustomModelPostProcessingResponse =
  | {
      ok: true;
    }
  | CanUserRunCustomModelPostProcessingResponseNotAllowed;

export function canUserRunCustomModelPostProcessing({
  userQuotas,
  userIsTeamMember,
}: {
  userQuotas?: AppUserQuotas | null;
  userIsTeamMember?: boolean;
}): CanUserRunCustomModelPostProcessingResponse {
  if (!userQuotas) {
    return {
      ok: false,
      message: "Unable to find user subscription information.",
      title: "Unable to find user subscription information.",
      header: "Unable to find user subscription information. Please contact support.",
    };
  }

  const {
    tierV2 = AppUserSubscriptionTierV2.Free,
    numCustomModelPredictionCreditsUsed = 0,
    maxNumCustomModelPredictionCreditsTotal = 1,
  } = userQuotas;

  if (!tierV2 || tierV2 === AppUserSubscriptionTierV2.Free) {
    return {
      ok: false,
      message: "Please subscribe to start editing images.",
      title: "Please subscribe to start editing images.",
      header: "Custom model image editing feature is only available for subscribed users.",
    };
  }

  if (numCustomModelPredictionCreditsUsed >= maxNumCustomModelPredictionCreditsTotal) {
    if (userIsTeamMember) {
      return {
        ok: false,
        message: "Contact your team owner to increase your custom model quota.",
        title: "Contact team owner to increase quota.",
        header: "You have used all custom model credits.",
      };
    } else {
      return {
        ok: false,
        message: "Please subscribe to start editing images.",
        title: "Please subscribe to start editing images.",
        header: "Custom model image editing feature is only available for subscribed users.",
      };
    }
  }

  return {
    ok: true,
  };
}

export function canUserCreateCustomModel({ userQuotas }: { userQuotas?: AppUserQuotas | null }) {
  if (!userQuotas) {
    debugLog("User quotas is not defined yet.");

    return false;
  }

  return true;
}

type CanUserStartTrainingResponse =
  | {
      ok: true;
    }
  | {
      ok: false;
      message: string;
      title: string;
      header: string;
    };

export function canUserStartTraining({
  userQuotas,
  input,
  isTeamMember,
}: {
  userQuotas?: AppUserQuotas | null;
  input: CustomModelTrainingInput;
  isTeamMember?: boolean;
}): CanUserStartTrainingResponse {
  if (!userQuotas) {
    debugLog("User quotas is not defined yet.");

    return {
      ok: false,
      message: "No custom model training quota left.",
      title: "No custom model training credits left.",
      header: "You have used all custom model credits.",
    };
  }

  const {
    tierV2 = AppUserSubscriptionTierV2.Free,
    numCustomModelTrainingCreditsUsed = 0,
    maxNumCustomModelTrainingCreditsTotal = 1,
  } = userQuotas;

  if (numCustomModelTrainingCreditsUsed >= maxNumCustomModelTrainingCreditsTotal) {
    if (isTeamMember) {
      return {
        ok: false,
        message: "Contact your team owner to increase your custom model quota",
        title: "No custom model training credits left",
        header: "Contact team owner to increase quota",
      };
    }
    return {
      ok: false,
      message: "No custom model training quota left",
      title: "No custom model training credits left",
      header: "You have used all custom model credits",
    };
  }

  if (isFastTrainingBackendType(input.backendType) && tierV2 === AppUserSubscriptionTierV2.Free) {
    return {
      ok: false,
      message: "Please subscribe to use fast training",
      title: "Fast training is only available for subscribed users",
      header: "Please subscribe to use fast training feature",
    };
  }

  return {
    ok: true,
  };
}

export function canUserStartPrediction({ userQuotas }: { userQuotas?: AppUserQuotas | null }) {
  if (!userQuotas) {
    debugLog("User quotas is not defined yet.");

    return false;
  }

  const { numCustomModelPredictionCreditsUsed = 0, maxNumCustomModelPredictionCreditsTotal = 1 } =
    userQuotas;

  return numCustomModelPredictionCreditsUsed < maxNumCustomModelPredictionCreditsTotal;
}

export function getCustomModelDisplayName({
  modelId,
  customModelType = CustomModelType.Custom,
}: {
  modelId?: string;
  customModelType?: CustomModelType;
}) {
  modelId = modelId || generateUUID();
  return `${customModelType}-${getDisplayNameFromId(modelId)}`;
}

async function getCustomModelDatasetItemThumbnailStoragePath({
  backend,
  datasetItem,
}: {
  backend?: Backend;
  datasetItem: CustomModelDatasetItem;
}) {
  const { storagePath } = datasetItem;

  try {
    if (!backend) {
      return storagePath;
    }

    const assetMetadata = await backend.getAssetMetadataFromStoragePath(storagePath);

    if (!assetMetadata) {
      return storagePath;
    }

    const {
      thumbnail512StoragePath = undefined,
      thumbnail256StoragePath = undefined,
      thumbnail128StoragePath = undefined,
    } = assetMetadata;

    return (
      thumbnail512StoragePath || thumbnail256StoragePath || thumbnail128StoragePath || storagePath
    );
  } catch (error) {
    debugError("Error retrieving custom model dataset item thumbnail storage path: ", error);

    return storagePath;
  }
}

export async function getCustomModelDatasetItemThumbnailUrl({
  backend,
  storageManager,
  datasetItem,
}: {
  backend?: Backend;
  storageManager: FlairStorageManager;
  datasetItem: CustomModelDatasetItem;
}) {
  if (!storageManager || !backend) {
    return "";
  }

  try {
    const storagePath = await getCustomModelDatasetItemThumbnailStoragePath({
      backend,
      datasetItem,
    });

    if (!storagePath) {
      return "";
    }

    const url = await Assets.loadAssetFromPath({
      path: storagePath,
      storageManager,
    });

    return url || "";
  } catch (error) {
    debugError("Error retrieving custom model dataset item thumbnail URL: ", error);

    return "";
  }
}

export function getCustomModelDisplayCategory({ customModel }: { customModel: CustomModelInfo }) {
  const isHumanModel = customModel.customModelType === CustomModelType.VirtualModel;

  return isHumanModel
    ? "Human"
    : (customModel.frontendDisplayTemplateType ?? customModel.customModelType);
}

export function cleanTrainingCaption(text: string) {
  try {
    const tripleTickContent = extractTripleTickContent(text) || text;
    const parsedDirtyJSON = parseDirtyJSON(tripleTickContent) || {};

    if (typeof parsedDirtyJSON === "object" && "caption" in parsedDirtyJSON) {
      return parsedDirtyJSON.caption;
    }
  } catch (error) {
    debugError("Error parsing training caption: ", error);
  }
  return text;
}

export function flattenAllTrainings(
  allCustomModelTrainings: Record<string, CustomModelTrainingItem[]>,
): CustomModelTrainingItem[] {
  return Object.values(allCustomModelTrainings).flatMap((trainingArr) => trainingArr);
}

export function getTrainingsMentionedInPrompt({
  promptEditorState,
  allCustomModelTrainings,
}: {
  promptEditorState: CustomModelPlaygroundPromptEditorState;
  allCustomModelTrainings: Record<string, CustomModelTrainingItem[]>;
}): Record<string, CustomModelTrainingItem[]> {
  const trainingIdsMentioned = Object.values(promptEditorState.scaleConfigs ?? {})
    .map((config) => config.trainingId)
    .filter(Boolean);

  const trainingsMentioned: Record<string, CustomModelTrainingItem[]> = {};

  trainingIdsMentioned.forEach((trainingId) => {
    const modelId = Object.keys(allCustomModelTrainings).find((key) =>
      allCustomModelTrainings[key].some((t) => t.id === trainingId),
    );

    if (modelId) {
      if (!trainingsMentioned[modelId]) {
        trainingsMentioned[modelId] = [];
      }
      trainingsMentioned[modelId].push(
        ...allCustomModelTrainings[modelId].filter((t) => t.id === trainingId),
      );
    }
  });

  return trainingsMentioned;
}
